import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FORMAT_DATE_TIME } from 'app/utils/date/format';
import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';
import { DesktopDateTimePickerProps } from 'types/inputs';

/**
 *  Выбор даты и времени с клавиатуры.
 *
 * @param props - Props компонента.
 * @param props.value - Значение.
 * @param props.error - Ошибки.
 * @param props.maxDate - Максимальная дата '01.01.3000'.
 * @param props.views - Значения Даты (год, месяц, день, час)..
 * @param props.required - Флаг обязательности поля.
 * @returns JSX.Element.
 */
export const KeyboardDateTimePicker = ({
  value,
  maxDate = '01.01.3000 00:00',
  ...props
}: DesktopDateTimePickerProps) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={'ru'}
      localeText={{
        nextMonth: 'Следующий месяц',
        previousMonth: 'Предыдущий месяц',
      }}
    >
      <DateTimePicker
        ampm={false}
        inputFormat={'DD.MM.YYYY HH:mm'}
        //@ts-ignore
        // ругается на defaultValues. Нужно типизировать TextField.
        renderInput={(params) => <TextField {...params} />}
        views={['year', 'month', 'day', 'hours']}
        maxDate={parseDateToMoment(maxDate, FORMAT_DATE_TIME)}
        {...props}
        value={value || null}
        //@ts-ignore
        inputProps={{ placeholder: 'ДД.ММ.ГГГГ ЧЧ:ММ' }}
      />
    </LocalizationProvider>
  );
};
