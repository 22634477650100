import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React from 'react';

/**
 * Алерт кнопки показа геометрии дочерних элементов.
 *
 * @param {Function} props - Пропсы.
 * @param {Function} props.closeDialog - Функция закрыть диалоговое окно.
 * @param {Function} props.isOpen - Флаг.
 * @returns {JSX.Element}
 */
export const DialogShowChildrenButton = ({ isOpen, closeDialog }) => {
  return isOpen ? (
    <Dialog
      isOpen={isOpen}
      maxWidth={'sm'}
      textHeader={'Сообщение'}
      Body={
        <>
          <p>ОГХ не имеет элементов благоустройства.</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              minWidth: 400,
            }}
          >
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={closeDialog}
            >
              Закрыть
            </Button>
          </div>
        </>
      }
    />
  ) : null;
};
