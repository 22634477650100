import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { BikeParkTypeIdField } from './BikeParkTypeId.Field';
import { useSetupBikeParkTypeIdField } from './useSetup.BikeParkTypeId.Field';

vi.mock('./useSetup.BikeParkTypeId.Field');

describe('🐛 BikeParkTypeIdFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useSetupBikeParkTypeIdField.mockReturnValue({});
    // 🔥 Act
    const wrapper = shallow(<BikeParkTypeIdField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<BikeParkTypeIdRF />');
  });
  it('🧪 props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useSetupBikeParkTypeIdField.mockReturnValue({
      disabled: RANDOM_BOOL,
      filter: vi.fn(),
    });
    // 🔥 Act
    const wrapper = shallow(<BikeParkTypeIdField />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: RANDOM_BOOL,
      filter: expect.any(Function),
      required: true,
    });
  });
});
