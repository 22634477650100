import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';

const SPR_NUMBER = 'spr21';

/**
 * Setup MountingModeIdField.
 *
 * @returns {object}
 */
export const useSetupMountingModeIdField = () => {
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  /**
   * Функция фильтрации для mounting_mode.
   *
   * @param {Array} dict - Данные для фильтрации.
   * @returns {Array} Отфильтрованные данные.
   */
  const filter = (dict) => {
    return dict.filter((item) => item.fz_spr.includes(SPR_NUMBER));
  };

  const disabled = !(editMode && isEditCurrentObjectIsExternalSystem);

  return { disabled, filter };
};
