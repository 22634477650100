import { useMapContext } from 'app/components/map/useMapContext';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import toArray from 'app/utils/toArray';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';

import { createMessage } from './createMessage';
import { postReonIntersection } from './postReonIntersection';

/**
 * Хук получения пересечений.
 *
 * @param {object} params - Параметры.
 * @returns {object} Объект с методом получения пересечений.
 */
export const useGetReonIntersectionsDTW = (params) => {
  const { showAlert, setReonIntersections } = params;
  const { drawReonIntersectionsWithFormat } = useMapContext();

  const [getReonIntersections, { isLoading }] = useMutationAdaptor(
    postReonIntersection,
    {
      //

      /**
       * Ошибочный кейс.
       *
       * @param {object} error - Ошибка.
       */
      onError: (error) => {
        log.error(error);
        log.info('reonintesection не прошел', error);
      },

      /**
       * Успешный кейс.
       *
       * @param {object} data - Ответ.
       * @param {object} request - Запрос с данными на сервер.
       * @returns {void}
       */
      onSuccess: (data, request) => {
        if (!request) {
          setReonIntersections(false);
          return drawReonIntersectionsWithFormat([]);
        }
        const intersections = toArray(data);
        if (intersections.length > 0) {
          showAlert(createMessage(intersections, message.reonIntersections));
          drawReonIntersectionsWithFormat(intersections);
          setReonIntersections(true);
        } else {
          showAlert(message.success);
        }
      },
    },
  );

  return { getReonIntersections, isLoading };
};
