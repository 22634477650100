import { useSetupOldReportsYard } from 'app/pages/registry/ogh/yard/Yard/Old.Reports.Yard/useSetup.Old.Reports.Yard';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Menu } from 'core/uiKit/components/Menu';
import { ExpandMoreIcon } from 'core/uiKit/material-ui';
import React from 'react';

import { ListItemYard } from '../ListItemYard';
import { MenuItemYard } from '../MenuItemYard';

/**
 * Компонент ReportsYard.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const OldReportsYard = ({ typeId }) => {
  const {
    handleClick,
    handleClose,
    anchorEl,
    prepareFilters,
    sendSummerCleaningWithSummary,
    loadingSummerCleaningWithSummary,
    sendSummerCleaning,
    loadingSummerCleaning,
    sendWinterCleaningWithSummary,
    loadingWinterCleaningWithSummary,
    sendWinterCleaning,
    loadingWinterCleaning,
    sendDtClassificationWithSummary,
    loadingDtClassificationWithSummary,
    sendDtClassification,
    loadingDtClassification,
    sendMafWithSummary,
    loadingMafWithSummary,
    sendMaf,
    loadingMaf,
    sendGreeningWithSummary,
    loadingGreeningWithSummary,
    sendGreening,
    loadingGreening,
    sendCoverWithSummary,
    loadingCoverWithSummary,
    sendCover,
    loadingCover,
    sendDtWithSummary,
    loadingDtWithSummary,
    sendDt,
    loadingDt,
  } = useSetupOldReportsYard({ typeId });

  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        endIcon={<ExpandMoreIcon />}
      >
        Отчёты
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted={true}
        open={Boolean(anchorEl)}
        PaperProps={{
          style: {
            maxHeight: 450,
          },
        }}
        onClose={handleClose}
      >
        <MenuItemYard text={'Летняя уборка'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendSummerCleaningWithSummary(prepareFilters)}
            loading={loadingSummerCleaningWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendSummerCleaning(prepareFilters)}
            loading={loadingSummerCleaning}
          />
        </MenuItemYard>

        <MenuItemYard text={'Зимняя уборка'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendWinterCleaningWithSummary(prepareFilters)}
            loading={loadingWinterCleaningWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendWinterCleaning(prepareFilters)}
            loading={loadingWinterCleaning}
          />
        </MenuItemYard>

        <MenuItemYard text={'Классификация ДТ'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendDtClassificationWithSummary(prepareFilters)}
            loading={loadingDtClassificationWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendDtClassification(prepareFilters)}
            loading={loadingDtClassification}
          />
        </MenuItemYard>

        <MenuItemYard text={'МАФы'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendMafWithSummary(prepareFilters)}
            loading={loadingMafWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendMaf(prepareFilters)}
            loading={loadingMaf}
          />
        </MenuItemYard>
        <MenuItemYard text={'Озеленение'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendGreeningWithSummary(prepareFilters)}
            loading={loadingGreeningWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendGreening(prepareFilters)}
            loading={loadingGreening}
          />
        </MenuItemYard>

        <MenuItemYard text={'Покрытие и элементы сопряжения'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendCoverWithSummary(prepareFilters)}
            loading={loadingCoverWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendCover(prepareFilters)}
            loading={loadingCover}
          />
        </MenuItemYard>

        <MenuItemYard text={'Общий отчёт по форме ТС'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendDtWithSummary(prepareFilters)}
            loading={loadingDtWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendDt(prepareFilters)}
            loading={loadingDt}
          />
        </MenuItemYard>
      </Menu>
    </>
  );
};
