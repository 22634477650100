import { PanelRightCard } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/PanelRightCard';
import React from 'react';

import { useGetAllowedGeometryTypesFF } from './hooks/useGetAllowedGeometryTypes';

/**
 * Класс MapEditPanelWrapper.
 *
 * @returns {JSX.Element}
 */
const MapEditPanelWrapper = () => {
  const allowedGeometryTypes = useGetAllowedGeometryTypesFF();

  return <PanelRightCard allowedGeometryTypes={allowedGeometryTypes} />;
};

export default MapEditPanelWrapper;
