import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { IContainerTypeData } from 'core/uiKit/preparedInputs/selects/simpleSelect/ContainerTypeId';

/**
 * Setup для Тип МНО для контейнера.
 *
 * @returns JSX.Element.
 */
export const useSetupContainerTypeIdFieldContainer = () => {
  const card = useGetCurrentCard();
  const cardParent = useGetParentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  const isCreate = !card.root_id;

  /**
   * Функция фильтрации.
   *
   * @param data - Данные.
   * @returns Данные.
   */
  const filter = (data: IContainerTypeData[]) => {
    return data.filter((item) =>
      item?.attribute?.parent_ogh_object_type_list?.includes(
        +cardParent?.type_id,
      ),
    );
  };
  return {
    filter,
    isEdit: editMode && isCreate && isEditCurrentObjectIsExternalSystem,
  };
};
