import { passportDraftOrgValidation } from 'app/components/card/ogh/Odh/validation/passport_draft_org.validation';
import { IS_ORPHAN_OBJECT_NAME } from 'core/form/reduxForm/fields/prepared/checkboxes/IsOrphanObject.RF';

describe('🐛 PassportDraftOrgValidationSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const result = passportDraftOrgValidation({});

    // ❓ Assert
    expect(result).toStrictEqual({
      passport_draft_org: 'обязательное поле',
    });
  });
  it('🧪 [IS_ORPHAN_OBJECT_NAME]: true', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const result = passportDraftOrgValidation({
      [IS_ORPHAN_OBJECT_NAME]: true,
    });

    // ❓ Assert
    expect(result).toStrictEqual(null);
  });
  it('🧪 passport_draft_org have value', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const result = passportDraftOrgValidation({ passport_draft_org: 1 });

    // ❓ Assert
    expect(result).toStrictEqual(null);
  });
});
