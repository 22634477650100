import { post } from 'app/api/crud/post';

/**
 * Метод Полученмя пересечений РЕОН.
 *
 * @param {object} data - Данные.
 * @returns {object}
 */
export const postReonIntersection = (data) => {
  if (!data) {
    return Promise.resolve([]);
  }
  return post('/ogh/geometry/reon/intersection', data);
};
