export const POINT = 'Point';

export const LINESTRING = 'LineString';

export const POLYLINE = 'Polyline';

export const LINEAR_RING = 'LinearRing';

export const POLYGON = 'Polygon';

export const MULTI_POINT = 'MultiPoint';

export const MULTI_LINESTRING = 'MultiLineString';

export const MULTILINESTRING = 'MultiLineString';

export const MULTI_POLYGON = 'MultiPolygon';

export const MULTIPOLYGON = 'MultiPolygon';

export const GEOMETRY_COLLECTION = 'GeometryCollection';

export const CIRCLE = 'Circle';

export const HOLE = 'Hole';
