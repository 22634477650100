import { IS_ORPHAN_OBJECT_NAME } from 'core/form/reduxForm/fields/prepared/checkboxes/IsOrphanObject.RF';
import { Validator } from 'core/form/Validator';

/**
 * Валидация поля __Инициатор разработки паспорта__.
 *
 * @param {object} formValues - Значения формы.
 * @returns {Record<string, string>}
 */
export const passportDraftOrgValidation = (formValues) => {
  const validator = new Validator(formValues);
  if (!formValues[IS_ORPHAN_OBJECT_NAME]) {
    validator.setRequired('passport_draft_org');
  }

  return validator.getErrors();
};
