import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
// import { useMapContext } from 'app/components/map/useMapContext';
import { LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useMapglEditorContext } from 'core/uiKit/components/DTW/contexts/MapglEditorContextProvider';
import { GeometryTypes } from 'core/uiKit/components/DTW/contexts/utils/types.d';
import { useState } from 'react';

/**
 * Setup for Компонент PanelRightCard.
 *
 * @param {{allowedGeometryTypes: object}} props - Жопа.
 * @returns {JSX.Element} - JSX.
 */
export const useSetupPanelRightCardDTW = ({ allowedGeometryTypes }) => {
  const [activeGeometry, setActiveGeometry] = useState(null);
  const { editMode } = useMode();
  // const context = useMapContext();
  const context = useMapglEditorContext();

  const currentCard = useGetCurrentCard();

  const polygonAllowed = allowedGeometryTypes.includes(POLYGON);
  const pointAllowed = allowedGeometryTypes.includes(POINT);
  const lineAllowed = allowedGeometryTypes.includes(LINESTRING);

  /**
   * Метод onDrawClick.
   *
   * @param {boolean} active - Флаг.
   * @param {*} geometryType - Тип геометрии.
   * @param {string} layerType - Тип слоя.
   * @returns {void} - Nothing.
   * @example ---
   */
  const onDrawClick = (active, geometryType, layerType) => {
    if (!active) {
      if (geometryType !== activeGeometry) {
        let isCanceled = activeGeometry !== GeometryTypes.Point;
        stopDrawing(isCanceled);
        context.mapService.enableEditing({}, false);
      }

      let coordinates = [];
      let id = 'drawing';
      if (geometryType === GeometryTypes.Point) {
        const { mapService } = context;
        const point =
          mapService.geometriesData[mapService.selectedLayerType].point[0];
        if (point) {
          coordinates = point.options.userData.coordinates;
          id = point.options.userData.id;
        }
      }

      setActiveGeometry(geometryType);
      context.mapService.finishEditing();
      context.mapService?.editService?.disableEditing();
      context.setIsDrawing(true);

      /**
       * Чисто для перевода типа для DTW.
       */
      const geometryTypeDTW =
        geometryType === LINESTRING ? 'polyline' : geometryType.toLowerCase();

      context.startDrawingGeometry(geometryTypeDTW, {
        coordinates,
        mapService: context.mapService,

        /**
         * OnDrawFinish..
         *
         */
        onDrawFinish: () => {
          stopDrawing();
          context.mapService.enableEditing({}, false);
        },
        userData: {
          coordinates,
          id,
          layerType: layerType || 'parent',
          mapService: context.mapService,
          type: geometryTypeDTW,
        },
      });
    } else {
      let isCanceled = activeGeometry !== GeometryTypes.Point;
      stopDrawing(isCanceled);
      context.mapService.enableEditing({}, false);
    }
  };

  /**
   * Функция stopDrawing.
   *
   * @param {boolean} isCanceled - Флаг отмены.
   *
   * @returns {void} - Nothing.
   */
  const stopDrawing = (isCanceled = true) => {
    context.setIsDrawing(false);
    setActiveGeometry(null);

    if (isCanceled) {
      context.mapService.cancelDrawing();
      return;
    }

    context.mapService.drawService?.finishDrawingHandler();
  };

  /**
   * Функция получения текущей геометрии.
   *
   * @returns {*} Геометрия.
   */
  const getGeometry = () => {
    return context.getGeometry(currentCard.recordId);
  };

  return {
    activeGeometry,
    context,
    currentCard,
    editMode,
    getGeometry,
    lineAllowed,
    onDrawClick,
    pointAllowed,
    polygonAllowed,
  };
};
