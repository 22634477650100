import { ToggleButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ToggleButton';
import React from 'react';

import { useSetupCheckGeometryButton } from './useSetup.CheckGeometry.Button';

const content = {
  off: {
    className: 'geom-button check-geometry-off material-icons',
    title: 'Показать пересечения со смежными ОГХ',
  },
  on: {
    className: 'geom-button check-geometry-on material-icons',
    title: 'Скрыть пересечения со смежными ОГХ',
  },
};

/**
 * Компонент кнопки CheckGeometryButton .
 *
 * @returns JSX.
 */
export const CheckGeometryButton = () => {
  const { active, isLoading, onClick } = useSetupCheckGeometryButton();

  return (
    <ToggleButton
      disabled={isLoading}
      isLoading={isLoading}
      active={active}
      content={content}
      onClick={onClick}
    />
  );
};
