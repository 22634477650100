import { printReport } from 'app/pages/registry/ogh/api';
import { useFilterQueryParamsYard } from 'app/pages/registry/ogh/ozn/Ozn/Filter.Ozn';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 *
 * Setup for Отчеты для МАФы.
 *
 * @returns Setup.
 */
export const useSetupDtMafMenuItem = () => {
  const { prepareFilters } = useFilterQueryParamsYard();

  const [sendMaf, { isLoading: loadingMaf }] = useMutationAdaptor(() =>
    printReport('dt_maf')(prepareFilters),
  );

  const [sendMafWithSummary, { isLoading: loadingMafWithSummary }] =
    useMutationAdaptor(() => printReport('dt_maf_summary')(prepareFilters));

  return {
    loadingMaf,
    loadingMafWithSummary,
    sendMaf,
    sendMafWithSummary,
  };
};
