export default {
  errorIntersections:
    'Ошибка пересечения геометрий. Скорректируйте границы ОГХ.',
  errorIntersectionsMore:
    'Ошибка пересечения геометрий. Количество пересекающих геометрий > 10. Скорректируйте границы ОГХ.',
  errorMore:
    'Геометрия элемента выходит за границы объекта. Количество объектов > 10. Скорректируйте границы ОГХ.',
  errorOther:
    'Геометрия элемента выходит за границы объекта. Скорректируйте границы ОГХ.',
  success: 'Пересечения отсутствуют',
};
