import { printReport } from 'app/pages/registry/ogh/api';
import { useFilterQueryParamsYard } from 'app/pages/registry/ogh/ozn/Ozn/Filter.Ozn';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 *
 * Setup for Отчеты для Общий отчёт по форме ТС.
 *
 * @returns Setup.
 */
export const useSetupDtMenuItem = () => {
  const { prepareFilters } = useFilterQueryParamsYard();

  const [sendDt, { isLoading: loadingDt }] = useMutationAdaptor(() =>
    printReport('dt_tl')(prepareFilters),
  );

  const [sendDtWithSummary, { isLoading: loadingDtWithSummary }] =
    useMutationAdaptor(() => printReport('dt_tl_summary')(prepareFilters));

  return {
    loadingDt,
    loadingDtWithSummary,
    sendDt,
    sendDtWithSummary,
  };
};
