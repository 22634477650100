import mapgl from '@2gis/mapgl/types';
import { useRef } from 'react';
import { useEffect, useState } from 'react';

import { MapService } from '../MapService/MapService';
import Shape from '../ShapeEditor/utils/Controls/Shape';

/**
 * Создает сервис для работы с картой.
 *
 * @param {typeof mapgl | null} loadedMapgl - Загруженная библиотека mapgl.
 * @param {string} id - Идентификатор карты. Так же является id контейнера для карты.
 * @returns {{mapService: MapService | null, mapServiceRef: React.MutableRefObject<MapService | null>}} Сервис для работы с картой и его реф.
 */
const useCreateMapService = (loadedMapgl: typeof mapgl | null, id: string) => {
  const [mapService, setMapService] = useState<MapService | null>(null);
  const mapServiceRef = useRef<MapService | null>(null);

  useEffect(() => {
    if (!loadedMapgl || !id) return;

    if (!MapService.isMapLoaded) MapService.initMapgl(loadedMapgl);
    if (!Shape.mapgl) Shape.initMapgl(loadedMapgl);

    if (mapServiceRef.current?.map) mapServiceRef.current.map.destroy();

    const mapService = new MapService(
      id,
      [
        'intersections',
        'districts',
        'parent',
        'selected',
        'children',
        'allChildren',
        'adjacent',
        'reonArea',
        'copyDiff',
        'originalDiff',
      ],
      {},
    );
    setMapService(mapService);
    mapServiceRef.current = mapService;
  }, [loadedMapgl, id]);

  return { mapService, mapServiceRef };
};

export default useCreateMapService;
