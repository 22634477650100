import React from 'react';

import { ListItemYard } from '../../ListItemYard';
import { MenuItemYard } from '../../MenuItemYard';
import { useSetupDtGreeningMenuItem } from './useSetup.DtGreening.MenuItem';

/**
 *
 * Отчеты для Озеленение.
 *
 * @returns MenuItem.
 */
export const DtGreeningMenuItem = () => {
  const {
    sendGreening,
    sendGreeningWithSummary,
    loadingGreening,
    loadingGreeningWithSummary,
  } = useSetupDtGreeningMenuItem();

  return (
    <MenuItemYard text={'Озеленение'}>
      <ListItemYard
        text={'Сводный'}
        onClick={sendGreeningWithSummary}
        loading={loadingGreeningWithSummary}
      />
      <ListItemYard
        text={'Подробный'}
        onClick={sendGreening}
        loading={loadingGreening}
      />
    </MenuItemYard>
  );
};
