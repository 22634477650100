import { printReport } from 'app/pages/registry/ogh/api';
import { useFilterQueryParamsYard } from 'app/pages/registry/ogh/ozn/Ozn/Filter.Ozn';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 *
 * Setup for Отчеты для Озеленение.
 *
 * @returns Setup.
 */
export const useSetupDtGreeningMenuItem = () => {
  const { prepareFilters } = useFilterQueryParamsYard();

  const [sendGreening, { isLoading: loadingGreening }] = useMutationAdaptor(
    () => printReport('dt_greening')(prepareFilters),
  );

  const [sendGreeningWithSummary, { isLoading: loadingGreeningWithSummary }] =
    useMutationAdaptor(() =>
      printReport('dt_greening_summary')(prepareFilters),
    );

  return {
    loadingGreening,
    loadingGreeningWithSummary,
    sendGreening,
    sendGreeningWithSummary,
  };
};
