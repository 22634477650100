import { printReport } from 'app/pages/registry/ogh/api';
import { useFilterQueryParamsYard } from 'app/pages/registry/ogh/ozn/Ozn/Filter.Ozn';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Setup для Отчеты для Информация о характеристиках ДТ.
 *
 * @returns Setup.
 */
export const useSetupDtClassificationMenuItem = () => {
  const { prepareFilters } = useFilterQueryParamsYard();

  const [sendDtClassification, { isLoading: loadingDtClassification }] =
    useMutationAdaptor(() => printReport('dt_classification')(prepareFilters));

  const [
    sendDtClassificationWithSummary,
    { isLoading: loadingDtClassificationWithSummary },
  ] = useMutationAdaptor(() =>
    printReport('dt_classification_summary')(prepareFilters),
  );

  return {
    loadingDtClassification,
    loadingDtClassificationWithSummary,
    sendDtClassification,
    sendDtClassificationWithSummary,
  };
};
