import { RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { EquipmentTypeIdField } from './EquipmentTypeId.Field';
import { useSetupEquipmentTypeIdField } from './useSetup.EquipmentTypeId.Field';

vi.mock('./useSetup.EquipmentTypeId.Field');

describe('🐛 EquipmentTypeIdField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useSetupEquipmentTypeIdField.mockReturnValue({
      disabled: false,
      filter: Function,
    });

    // 🔥 Act
    const wrapper = shallow(<EquipmentTypeIdField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<EquipmentTypeIdRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useSetupEquipmentTypeIdField.mockReturnValue({
      disabled: false,
      filter: Function,
    });

    // 🔥 Act
    const wrapper = shallow(<EquipmentTypeIdField someProp={RANDOM_WORD} />);

    // ❓ Assert
    const equipmentTypeIdRF = wrapper.find('EquipmentTypeIdRF');

    expect(equipmentTypeIdRF.prop('disabled')).toBe(false);
    expect(equipmentTypeIdRF.prop('filter')).toEqual(expect.any(Function));
    expect(equipmentTypeIdRF.prop('required')).toBe(true);
    expect(equipmentTypeIdRF.prop('someProp')).toBe(RANDOM_WORD);
  });
});
