import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPreparedProps } from 'types/inputs';

import { useGetOptionsBikeParkTypeId } from './useGetOptionsBikeParkTypeId';

export const BIKE_PARK_TYPE_ID_NAME = 'bike_park_type_id';

/**
 * ## Select __Тип велопарковки__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const BikeParkTypeId = (props: SelectPreparedProps) => {
  const data = useGetOptionsBikeParkTypeId();

  return (
    <Select
      {...props}
      // @ts-ignore
      options={data}
      label={'Тип велопарковки'}
      name={BIKE_PARK_TYPE_ID_NAME}
    />
  );
};
