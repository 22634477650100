import React from 'react';

import { ListItemYard } from '../../ListItemYard';
import { MenuItemYard } from '../../MenuItemYard';
import { useSetupDtClassificationMenuItem } from './useSetup.DtClassification.MenuItem';

/**
 * Отчеты для Информация о характеристиках ДТ.
 *
 * @returns MenuItem.
 */
export const DtClassificationMenuItem = () => {
  const {
    loadingDtClassification,
    loadingDtClassificationWithSummary,
    sendDtClassification,
    sendDtClassificationWithSummary,
  } = useSetupDtClassificationMenuItem();

  return (
    <MenuItemYard text={'Информация о характеристиках ДТ'}>
      <ListItemYard
        text={'Сводный'}
        onClick={sendDtClassificationWithSummary}
        loading={loadingDtClassificationWithSummary}
      />
      <ListItemYard
        text={'Подробный'}
        onClick={sendDtClassification}
        loading={loadingDtClassification}
      />
    </MenuItemYard>
  );
};
