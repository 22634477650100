import submitCard from 'app/components/card/ogh/submit';
import { CLEANING_TYPE_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType';
import {
  AUTO_CLEANING_TYPE,
  MANUAL_CLEANING_TYPE,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType/cleaningTypes';
import { vi } from 'vitest';

import { onSubmitDts } from './onSubmit.Dts';

vi.mock('app/components/card/ogh/submit');
vi.mock('app/components/card/common/validation/index');

/**
 * Mock Default.
 *
 */
const mockDefault = () => {};

describe('🐛 spec onSubmit.Dts', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = onSubmitDts({});

    //❓ Assert
    expect(res).toStrictEqual(expect.any(Function));
  });

  it('🧪 CLEANING_TYPE_NAME нет нихто', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    onSubmitDts({ as: 3 })({});

    //❓ Assert
    expect(submitCard).toHaveBeenCalledWith(
      {
        as: 3,
      },
      {
        attribute: {
          file_list: [],
          meadow_lowland_area: NaN,
          snow_clean_area: NaN,
          spread: NaN,
        },
        interval: {},
      },
    );
  });

  it('🧪 CLEANING_TYPE_NAME == MANUAL_CLEANING', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    onSubmitDts({ as: 3 })({
      [CLEANING_TYPE_NAME]: MANUAL_CLEANING_TYPE,
      total_area: 123,
    });

    //❓ Assert
    expect(submitCard).toHaveBeenCalledWith(
      {
        as: 3,
      },
      {
        attribute: {
          file_list: [],
          manual_clean_area: 123,
          meadow_lowland_area: NaN,
          snow_clean_area: NaN,
          spread: NaN,
          total_area: 123,
        },
        interval: {},
      },
    );
  });

  it('🧪 CLEANING_TYPE_NAME == AUTO_CLEANING', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    onSubmitDts({ as: 3 })({
      [CLEANING_TYPE_NAME]: AUTO_CLEANING_TYPE,
      total_area: 123,
    });

    //❓ Assert
    expect(submitCard).toHaveBeenCalledWith(
      {
        as: 3,
      },
      {
        attribute: {
          auto_clean_area: 123,
          file_list: [],
          meadow_lowland_area: NaN,
          snow_clean_area: NaN,
          spread: NaN,
          total_area: 123,
        },
        interval: {},
      },
    );
  });
});
