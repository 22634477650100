import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import { BUILDINGS_TYPE_NAME, BuildingType } from 'core/uiKit/preparedInputs';
import React from 'react';
import { SelectRFProps } from 'types/inputs';

/**
 * Select RF __Назначение__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const BuildingsTypeIdRF = (props: SelectRFProps) => {
  return (
    <FieldRF {...props} component={BuildingType} name={BUILDINGS_TYPE_NAME} />
  );
};
