import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';

import { useGetReonIntersectionsDTW } from './useGetReonIntersections/useGetReonIntersections.DTW';
import { useReonIntersections } from './useReonIntersections';

/**
 * Setup Кнопка Показать пересечения с данными ИС РЕОН.
 *
 * @param {object} props - Параметры.
 * @param {(message: string)=> void} props.showAlert - Функция вызова Алерта.
 * @returns {void}
 */
export const useSetupReonButtonWithAlertDTW = ({ showAlert }) => {
  const { mapService, getGeometryToJson } = useMapContext();

  const { hasReonIntersections, setReonIntersections } = useReonIntersections();
  const { recordId } = useGetCurrentCard();
  const { getReonIntersections, isLoading } = useGetReonIntersectionsDTW({
    setReonIntersections,
    showAlert,
  });

  /**
   * Функция checkGeometryReonByButton.
   *
   */
  const checkGeometryReonByButton = () => {
    if (hasReonIntersections) {
      getReonIntersections();
    } else {
      const data = {
        geometry: getGeometryToJson(recordId),
      };

      getReonIntersections(data);
    }
  };
  return {
    checkGeometryReonByButton,
    hasReonIntersections,
    isLoading,
    isMapLoaded: mapService?.isMapLoaded,
  };
};
