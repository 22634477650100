// import { Button } from 'core/uiKit/components/buttons/Button';
// import { InfoPanel } from 'core/uiKit/components/InfoPanel';
import React from 'react';

import { ToggleButton } from '../ToggleButton';
import { InfoPanelNeighboursButton } from './InfoPanel.Neighbours.Button';
import { useSetupNeighboursButton } from './useSetup.Neighbours.Button';

const content = {
  off: {
    className: 'geom-button neighbours-off material-icons',
    title: 'Показать смежные объекты',
  },

  on: {
    className: 'geom-button neighbours-on material-icons',
    title: 'Скрыть смежные объекты',
  },
};

/**
 * Кнопка показать смежные ОГХ.
 *
 * @returns {JSX.Element}
 */
export const NeighboursButton = () => {
  const {
    closeDialog,
    count,
    handleClickOpen,
    hasNeighbours,
    isLoading,
    isMapLoaded,
    isOpen,
    neighbors,
    params,
    radius,
  } = useSetupNeighboursButton();

  return (
    <>
      {isOpen ? (
        <InfoPanelNeighboursButton
          {...{
            closeDialog,
            count,
            isLoading,
            neighbors,
            params,
            radius,
          }}
        />
      ) : null}
      <ToggleButton
        isLoading={isLoading}
        disabled={!isMapLoaded}
        active={hasNeighbours}
        content={content}
        onClick={handleClickOpen}
      />
    </>
  );
};
