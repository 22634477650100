import React from 'react';

import { ContinueDialog } from '../dialogs/Continue.Dialog';
import { useSetupPrompt } from './useSetup.Prompt';

interface PromptProps {
  when: boolean;
  message: string;
  send?: () => void;
}

/**
 * Используется для подсказки пользователю перед уходом со страницы.
 *
 * @param {PromptProps} props - Properties.
 * @returns React component.
 */
export const Prompt = ({ when, message, send }: PromptProps) => {
  const { closeDialog, isShow, sendHandler } = useSetupPrompt({ send, when });

  return isShow ? (
    <ContinueDialog
      closeDialog={closeDialog}
      isOpen={isShow}
      send={sendHandler}
      textHeader={'Подтвердите действие'}
      Body={<div>{message}</div>}
    />
  ) : null;
};
