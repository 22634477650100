import { IGeometryIntersectionData } from 'types/api.d';

import { formatResponseToGeoJSON } from './formatResponseToGeoJSON';

/**
 * Форматирует данные для отрисовки пересечения.
 *
 * @param params - Параметры.
 * @returns Форматированные данные.
 */
export const prepareForIntersectionGeometry = (
  params: IGeometryIntersectionData[],
) => {
  const formattedResponse = params.reduce((acc, value) => {
    const accValue = {
      ...value,
      geometry: {
        ...formatResponseToGeoJSON(value?.geometry),
      },
    };

    // @ts-ignore
    acc.push(accValue);

    return acc;
  }, []);

  return formattedResponse.map((item) => createObjectForMapDTW(item));
};

/**
 * Создает объект для отрисовки на карте.
 *
 * @param item - Объект с данными.
 * @returns Объект с данными геометрии.
 */
export const createObjectForMapDTW = (item: IGeometryIntersectionData) => {
  const hintFields = [
    {
      key: 'title',
      rawValue: 'Объект РЕОН',
      value: 'Объект РЕОН',
    },
    {
      key: 'descr',
      rawValue: item.descr,
      value: `Кадастровый номер: ${item.descr}`,
    },
    {
      key: 'prizn_sobstv',
      rawValue: item.prizn_sobstv,
      value: `Признак собственности: ${item.prizn_sobstv}`,
    },
    {
      key: 'prizn_prava',
      rawValue: item.prizn_prava,
      value: `Признак оформленного права: ${item.prizn_prava}`,
    },
    {
      key: 'Адрес',
      rawValue: item.address,
      value: `Адрес: ${item.address}`,
    },
  ];

  const hint = hintFields.filter((item) => item.rawValue);

  return {
    ...item.geometry,
    hint: {
      object_attribute_hint: hint,
      object_attribute_hint_short: [
        {
          key: 'name',
          value: item.descr,
        },
      ],
    },
  };
};
