export const mounting_mode = [
  {
    code: '34',
    fz_spr: ['spr21', 'spr24'],
    id: 43,
    name: '-',
  },
  {
    code: '47',
    fz_spr: [],
    id: 56,
    name: 'Тип',
  },
  {
    code: '121',
    fz_spr: [],
    id: 100063,
    name: 'Тип установки',
  },
];
