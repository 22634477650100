import './MapServiceSwitch.scss';

import { SERVIS_MAP, setServiceMap } from 'config/env';
import React from 'react';

/**
 * Временный компонент переключения сервиса карты.
 *
 * @returns
 */
export const useSetupMapServiceSwitch = () => {

  /**
   * Функция переключения.
   *
   * @param {Event} event - Событие Event.
   */
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setServiceMap(value);
    location.reload();
  };

  return {
    onChange,
    value: SERVIS_MAP,
  };
};
