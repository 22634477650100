import { validateRequiredFieldsAsync } from 'app/components/card/common/validation/index';
import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';
import submitCard from 'app/components/card/ogh/submit';
import { transformValues } from 'app/utils/form/transformation';
import { validateWithTypeOfCoating } from 'core/form/reduxForm/fields/prepared/selects/simple/CleaningType.RF/validateWithTypeOfCoating';
import { CLEANING_TYPE_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType';
import {
  AUTO_CLEANING_TYPE,
  MANUAL_CLEANING_TYPE,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType/cleaningTypes';

interface IFormValues {
  parent_name?: string;
  snow_clean_area: string | number;
  meadow_lowland_area: string | number;
  spread: string | number;
  total_area?: number;
  [CLEANING_TYPE_NAME]?: number;
  manual_clean_area?: number;
  auto_clean_area?: number;
  abutment_type_list?: string;
}

/**
 * Функция создания атрибутов (наверное).
 *
 * @param formValues - Значения формы.
 * @returns - Атрибуты.
 * @example
 * const formValuesWithAttribute = preparationValues({
 *   ...values,
 *   ...createAttribute(values),
 * });
 */
function createAttribute(formValues: IFormValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  {
    name: 'total_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'manual_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'auto_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];

const dictFields = [
  'dts_type_id',
  'coating_type_id',
  'abutment_type_id',
  'coating_group_id',
  'cleaning_type',
];

const mandatoryFields = [
  'dts_type_id',
  'coating_group_id',
  'coating_type_id',
  'total_area',
  'cleaning_type',
];

/**
 * Функция Submit.
 *
 * @param props - Параметры.
 * @param props.card - Карточка.
 * @param props.card.type_id - Тип карточки.
 * @returns Function onSubmit.
 * @example
 * const mapStateToProps = (state, props) => {
 *   return {
 *     enableReinitialize: true,
 *     initialValues: {
 *       startDate: parseDate(props.card.start_date),
 *       endDate: parseDate(props.card.end_date),
 *     onSubmit: onSubmit.bind({ props }),
 *     validate,
 *   };
 * };
 */
export const onSubmitDts = (props: unknown) => (formValues: IFormValues) => {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields,
    validateWithTypeOfCoating,
  );

  type NumericFields = 'snow_clean_area' | 'meadow_lowland_area' | 'spread';
  // форматирование в число - пока так
  ['snow_clean_area', 'meadow_lowland_area', 'spread'].forEach((property) => {
    const key = property as NumericFields;

    formValuesWithAttribute[key] = Number(formValuesWithAttribute[key]);
  });

  if (
    formValuesWithAttribute.total_area &&
    formValuesWithAttribute.total_area > 0
  ) {
    if (formValuesWithAttribute[CLEANING_TYPE_NAME] === MANUAL_CLEANING_TYPE) {
      formValuesWithAttribute.manual_clean_area =
        formValuesWithAttribute.total_area;
    }
    if (formValuesWithAttribute[CLEANING_TYPE_NAME] === AUTO_CLEANING_TYPE) {
      formValuesWithAttribute.auto_clean_area =
        formValuesWithAttribute.total_area;
    }
    delete formValuesWithAttribute[CLEANING_TYPE_NAME];
  }

  // if (get(formValuesWithAttribute, 'abutment_type_list.table[0]')) {
  //   formValuesWithAttribute.abutment_type_list =
  //     formValuesWithAttribute.abutment_type_list.table;
  // } else {
  // }
  if (formValuesWithAttribute?.abutment_type_list) {
    delete formValuesWithAttribute.abutment_type_list;
  }

  submitCard(
    props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
};
