import { printReport } from 'app/pages/registry/ogh/api';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Мутация для печати отчетов Old.
 *
 * @returns {object}
 */
export const usePrintReportOld = () => {
  const mutationOptions = {
    //

    /**
     * Функция onSuccess.
     */
    onSuccess: () => {},
  };

  // summercleaning
  const [sendSummerCleaning, { isLoading: loadingSummerCleaning }] =
    useMutationAdaptor(printReport('old_dt_cleaning_summer'), mutationOptions);

  const [
    sendSummerCleaningWithSummary,
    { isLoading: loadingSummerCleaningWithSummary },
  ] = useMutationAdaptor(
    printReport('old_dt_cleaning_summer_summary'),
    mutationOptions,
  );

  // wintercleaning
  const [sendWinterCleaning, { isLoading: loadingWinterCleaning }] =
    useMutationAdaptor(printReport('old_dt_cleaning_winter'), mutationOptions);

  const [
    sendWinterCleaningWithSummary,
    { isLoading: loadingWinterCleaningWithSummary },
  ] = useMutationAdaptor(
    printReport('old_dt_cleaning_winter_summary'),
    mutationOptions,
  );

  // dtclassification
  const [sendDtClassification, { isLoading: loadingDtClassification }] =
    useMutationAdaptor(printReport('old_dt_classification'), mutationOptions);

  const [
    sendDtClassificationWithSummary,
    { isLoading: loadingDtClassificationWithSummary },
  ] = useMutationAdaptor(
    printReport('old_dt_classification_summary'),
    mutationOptions,
  );

  // maf
  const [sendMaf, { isLoading: loadingMaf }] = useMutationAdaptor(
    printReport('old_dt_maf'),
    mutationOptions,
  );

  const [sendMafWithSummary, { isLoading: loadingMafWithSummary }] =
    useMutationAdaptor(printReport('old_dt_maf_summary'), mutationOptions);

  // greening
  const [sendGreening, { isLoading: loadingGreening }] = useMutationAdaptor(
    printReport('old_dt_greening'),
    mutationOptions,
  );

  const [sendGreeningWithSummary, { isLoading: loadingGreeningWithSummary }] =
    useMutationAdaptor(printReport('old_dt_greening_summary'), mutationOptions);

  // cover
  const [sendCover, { isLoading: loadingCover }] = useMutationAdaptor(
    printReport('old_dt_cover'),
    mutationOptions,
  );

  const [sendCoverWithSummary, { isLoading: loadingCoverWithSummary }] =
    useMutationAdaptor(printReport('old_dt_cover_summary'), mutationOptions);

  // tl/dt
  const [sendDt, { isLoading: loadingDt }] = useMutationAdaptor(
    printReport('old_dt_tl'),
    mutationOptions,
  );

  const [sendDtWithSummary, { isLoading: loadingDtWithSummary }] =
    useMutationAdaptor(printReport('old_dt_tl_summary'), mutationOptions);

  return {
    loadingCover,
    loadingCoverWithSummary,
    loadingDt,
    loadingDtClassification,
    loadingDtClassificationWithSummary,
    loadingDtWithSummary,
    loadingGreening,
    loadingGreeningWithSummary,
    loadingMaf,
    loadingMafWithSummary,
    loadingSummerCleaning,
    loadingSummerCleaningWithSummary,
    loadingWinterCleaning,
    loadingWinterCleaningWithSummary,
    sendCover,
    sendCoverWithSummary,
    sendDt,
    sendDtClassification,
    sendDtClassificationWithSummary,
    sendDtWithSummary,
    sendGreening,
    sendGreeningWithSummary,
    sendMaf,
    sendMafWithSummary,
    sendSummerCleaning,
    sendSummerCleaningWithSummary,
    sendWinterCleaning,
    sendWinterCleaningWithSummary,
  };
};
