import { IGeometryChildrenData } from 'types/api.d';

import { formatResponseToGeoJSON } from './formatResponseToGeoJSON';

/**
 *  Форматирование ответа для карты DTW.
 *
 * @param data - Данные с бэка.
 * @returns Форматированные данные.
 */
export const prepareForDrawAllChildrenGeometry = (
  data: IGeometryChildrenData[],
) => {
  const formattedResponse = data.reduce((acc, value) => {
    const accValue = {
      ...value,
      geometry: {
        ...formatResponseToGeoJSON(value),
        hint: value.hint,
      },
      id: value.id,
    };

    // @ts-ignore
    acc.push(accValue);

    return acc;
  }, []);

  const items = formattedResponse.map((item) => createObjectForMapDTW(item));

  return items;
};

/**
 * Создает объект для отрисовки на карте.
 *
 * @param item - Объект с данными.
 * @returns Объект с данными геометрии.
 */
export const createObjectForMapDTW = (item: IGeometryChildrenData) => {
  const hintFields = [
    {
      key: 'title',
      rawValue: 'Объект РЕОН',
      value: 'Объект РЕОН',
    },
  ];

  const hint = hintFields.filter((item) => item.rawValue);

  return {
    // @ts-ignore
    ...item.geometry,
    hint: {
      object_attribute_hint: hint,
      object_attribute_hint_short: [
        {
          key: 'name',
          // @ts-ignore
          value: item.descr,
        },
      ],
    },
  };
};
