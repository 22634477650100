import { DATE_SURVEY_NAME } from 'core/form/reduxForm/fields/prepared/datePickers/DateSurvey.RF';
import { INTENSITY_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/IntensityNum';
import { CATEGORY_SP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategorySpId';
import { vi } from 'vitest';

import onSubmit from './submit';

describe('🐛 spec submit', function () {
  it('🧪 test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const updateObject = vi.fn();
    const createObject = vi.fn();
    const alert = vi.fn();

    // Act
    onSubmit({
      props: {
        alert,
        card: {},
        createObject,
        mode: true,
        updateObject,
      },
    })({
      axis_length: 3,
      bridge_psc: '2',
      bridge_qty: '2',
      bridge_sqr: '2',
      cable_fancing_psc: '2',
      category_sp_id: '2',
      customer_id: { id: 3 },
      date_survey: '01.01.1970',
      decor_psc: '2',
      distance: 3,
      id: 2,
      lamp_qty: '2',
      layout_length: 3,
      light_psc: '2',
      light_qty: '2',
      name: 3,
      owner_id: { id: 3 },
      pass_dev_initiator: 3,
      passport_date: '01.01.1970',
      passport_draft_org: 3,
      pavilion_qty: '2',
      pedestrian_psc: '2',
      pedestrian_qty: '2',
      pedestrian_sqr: '2',
      police_qty: '2',
      police_sqr: '2',
      reagent_area: 4,
      rotor_area: 4,
      sandbase_qty: '2',
      sandbase_sqr: '2',
      snow_area: 3,
      snow_psc: '2',
      support_qty: '2',
      tlight_qty: '2',
      transform_qty: '2',
      tube_psc: '2',
      tube_qty: '2',
      type: 2,
      urn_qty: '2',
      wall_psc: '2',
      water_psc: '2',
      сase_outside_light_qty: '2',
    });
    // Assert
    expect(updateObject).toBeCalledWith({
      attribute: {
        axis_length: 3,
        bridge_psc: 2,
        bridge_qty: 2,
        bridge_sqr: 2,
        cable_fancing_psc: 2,
        category_sp_id: { category_sp: '2' },
        customer_id: { legal_person: 3 },
        date_survey: '01.01.1970',
        decor_psc: 2,
        distance: 3,
        file_list: [],
        id: 2,
        lamp_qty: 2,
        layout_length: 3,
        light_psc: 2,
        light_qty: 2,
        name: 3,
        owner_id: { legal_person: 3 },
        pass_dev_initiator: 3,
        passport_date: '01.01.1970',
        passport_draft_org: 3,
        pavilion_qty: 2,
        pedestrian_psc: 2,
        pedestrian_qty: 2,
        pedestrian_sqr: 2,
        police_qty: 2,
        police_sqr: 2,
        reagent_area: 4,
        rotor_area: 4,
        sandbase_qty: 2,
        sandbase_sqr: 2,
        snow_area: 3,
        snow_psc: 2,
        support_qty: 2,
        tlight_qty: 2,
        transform_qty: 2,
        tube_psc: 2,
        tube_qty: 2,
        type: 2,
        urn_qty: 2,
        wall_psc: 2,
        water_psc: 2,
        сase_outside_light_qty: 2,
      },
      calcAttribute: {},
    });
  });

  it('🧪 test is_orphan_object true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const updateObject = vi.fn();
    const createObject = vi.fn();
    const alert = vi.fn();

    const dataNotExpected = {
      [CATEGORY_SP_ID_NAME]: 1,
      [DATE_SURVEY_NAME]: 1,
      [INTENSITY_NUM_NAME]: 1,
      actual_schema_date: 1,
      description: 1,
      owner_id: 1,
      pass_dev_initiator: 1,
      passport_date: 1,
      passport_draft_org: 1,
      passport_num: 1,
    };

    // Act
    onSubmit({
      props: {
        alert,
        card: {},
        createObject,
        mode: true,
        updateObject,
      },
    })({
      asperity: 1,
      auto_footway_area: 1,
      axis_length: 3,
      bar_antinoise: 1,
      bar_concrete: 1,
      bar_foot: 1,
      bar_fracasso: 1,
      bar_new_jersey: 1,
      bar_pipe: 1,
      bar_trans: 1,
      bar_wave: 1,
      bridge_psc: '2',
      bridge_qty: '2',
      bridge_sqr: '2',
      buffer: 1,
      cable_fancing_psc: '2',
      category_sp_id: '2',
      customer_id: { id: 3 },
      date_survey: '01.01.1970',
      decor_psc: '2',
      distance: 3,
      file_list_19: 1,
      footway_area: 3,
      footway_pole: 1,
      gutters_length: 1,
      id: 2,
      inbound_area: 9,
      info: 1,
      is_orphan_object: true,
      lamp_qty: '2',
      layout_length: 3,
      light_psc: '2',
      light_qty: '2',
      manual_footway_area: 1,
      margin_area: 2,
      margin_auto_clean_area: 1,
      margin_manual_clean_area: 1,
      name: 3,
      owner_id: { id: 3 },
      pass_dev_initiator: 3,
      passport_date: '01.01.1970',
      pavilion_qty: '2',
      pedestrian_psc: '2',
      pedestrian_qty: '2',
      pedestrian_sqr: '2',
      pointer: 1,
      police_qty: '2',
      police_sqr: '2',
      reagent_area: 4,
      roadway_area: 9,
      roadway_noprkg_auto_clean_area: 1,
      roadway_noprkg_manual_clean_area: 1,
      roadway_prkg_auto_clean_area: 1,
      roadway_prkg_manual_clean_area: 1,
      rotor_area: 4,
      sandbase_qty: '2',
      sandbase_sqr: '2',
      sign: 1,
      snow_area: 0,
      snow_psc: '2',
      station_clean_area: 1,
      station_number: 1,
      support_qty: '2',
      tlight_qty: '2',
      transform_qty: '2',
      tube_psc: '2',
      tube_qty: '2',
      type: 2,
      urn_qty: '2',
      wall_psc: '2',
      water_psc: '2',
      сase_outside_light_qty: '2',
      ...dataNotExpected,
    });
    // Assert
    expect(updateObject).toBeCalledWith({
      attribute: {
        axis_length: 3,
        bridge_psc: 2,
        bridge_qty: 2,
        bridge_sqr: 2,
        cable_fancing_psc: 2,
        customer_id: {
          legal_person: 3,
        },
        decor_psc: 2,
        distance: 3,
        file_list: [],
        file_list_19: 1,
        id: 2,
        is_orphan_object: true,
        lamp_qty: 2,
        layout_length: 3,
        light_psc: 2,
        light_qty: 2,
        name: 3,
        pavilion_qty: 2,
        pedestrian_psc: 2,
        pedestrian_qty: 2,
        pedestrian_sqr: 2,
        police_qty: 2,
        police_sqr: 2,
        reagent_area: 4,
        rotor_area: 4,
        sandbase_qty: 2,
        sandbase_sqr: 2,
        snow_area: 0,
        snow_psc: 2,
        support_qty: 2,
        tlight_qty: 2,
        transform_qty: 2,
        tube_psc: 2,
        tube_qty: 2,
        type: 2,
        urn_qty: 2,
        wall_psc: 2,
        water_psc: 2,
        сase_outside_light_qty: 2,
      },
      calcAttribute: {
        asperity: 1,
        auto_footway_area: 1,
        bar_antinoise: 1,
        bar_concrete: 1,
        bar_foot: 1,
        bar_fracasso: 1,
        bar_new_jersey: 1,
        bar_pipe: 1,
        bar_trans: 1,
        bar_wave: 1,
        buffer: 1,
        footway_area: 3,
        footway_pole: 1,
        gutters_length: 1,
        inbound_area: 9,
        info: 1,
        manual_footway_area: 1,
        margin_area: 2,
        margin_auto_clean_area: 1,
        margin_manual_clean_area: 1,
        pointer: 1,
        roadway_area: 9,
        roadway_noprkg_auto_clean_area: 1,
        roadway_noprkg_manual_clean_area: 1,
        roadway_prkg_auto_clean_area: 1,
        roadway_prkg_manual_clean_area: 1,
        sign: 1,
        station_clean_area: 1,
        station_number: 1,
      },
      id: undefined,
      type: undefined,
    });
  });
});
