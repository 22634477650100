export const buildings_type = [
  {
    id: 84,
    name: 'Жилое',
  },
  {
    id: 91,
    name: 'Нежилое',
  },
];
