import 'assets/styles/mainPage.legendPanel.scss';

import Legend from 'app/components/common/Legend';
import { MapServiceSwitch } from 'app/components/map/Map/MapServiceSwitch';
import {
  childrenColors,
  districtBoundariesColors,
  intersectedColors,
  neighboursColors,
  ordersColors,
  parentsColors,
  reonColors,
  selfColors,
} from 'app/constants/colors';
import cn from 'classnames';
import collapsible from 'core/hocs/collapsible';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const legend = [
  {
    className: 'level',
    style: { backgroundColor: selfColors.legendColor },
    value: 'Выбранный объект',
  },
  {
    className: 'level',
    style: { backgroundColor: parentsColors.legendColor },
    value: 'Родительский объект',
  },
  {
    className: 'level',
    style: { backgroundColor: childrenColors.legendColor },
    value: 'Дочерние элементы',
  },
  {
    className: 'level',
    style: {
      backgroundColor: neighboursColors.legendColor,
    },
    value: 'Смежные объекты',
  },
  {
    className: 'level',
    style: {
      backgroundColor: intersectedColors.legendColor,
    },
    value: 'Пересекаемые объекты',
  },
  {
    className: 'level',
    style: { backgroundColor: ordersColors.legendColor },
    value: 'Ордера на работы',
  },
  {
    className: 'level',
    style: { backgroundColor: reonColors.legendColor },
    value: 'Участки ИС РЕОН',
  },
  {
    className: 'level',
    style: {
      backgroundColor: districtBoundariesColors.legendColor,
    },
    value: 'Границы районов',
  },
];

const CollapsibleLegend = collapsible(Legend);

const propTypes = {
  className: PropTypes.string,
};

/**
 * Компонент LegendPanel.
 *
 * @param {string} className - Css класс.
 * @returns {JSX.Element} - JSX.
 * @example <LegendPanel/>
 */
const LegendPanel = ({ className }) => (
  <div>
    {/*Временный свитчер когде перейдем на DTW карту удалить*/}
    <MapServiceSwitch />
    <div className={cn('legendPanel', className)}>
      <CollapsibleLegend
        buttonInverted={true}
        legend={legend}
        showHeaderHeight={50}
      />
    </div>
  </div>
);

LegendPanel.propTypes = propTypes;

export default memo(LegendPanel);
