import { PrintButton } from 'app/pages/components';
import GeometryCSVLink from 'app/utils/card/checkGeometry/GeometryCSVLink';
import { Button } from 'core/uiKit/components/buttons';
import { InfoPanel } from 'core/uiKit/components/InfoPanel';
import React from 'react';
import { IGeometryNeighbours, IGeometryNeighboursRequest } from 'types/api';

import { printNeighboursTable } from './print.Neighbours.Table';
import { TableNeighbours } from './Table.Neighbours';

interface InfoPanelNeighboursButtonProps {
  closeDialog: () => void;

  count: number;
  isLoading: boolean;
  neighbors: IGeometryNeighbours[];
  params: IGeometryNeighboursRequest;
  radius: number;
}

/**
 * Диалоговое окно информации о "Смежные объекты".
 *
 * @param props - Свойства.
 * @param props.closeDialog - Закрыть.
 * @param props.neighbors - Соседи.
 * @param props.count - Количество соседей.
 * @param props.radius - Радиус.
 * @param props.isLoading - Загрузка.
 * @param props.params - Параметры.
 * @returns - Диалоговое окно.
 */
export const InfoPanelNeighboursButton = ({
  closeDialog,
  count,
  isLoading,
  neighbors,
  params,
  radius,
}: InfoPanelNeighboursButtonProps) => {
  // const { queryTable } = useParamsTable();
  // const { getGeometryToJson } = useMapContext();
  // const { root_id, start_date, end_date, record_id } = useGetCurrentCard();
  //
  // const params = {
  //   end_date,
  //   geometry: getGeometryToJson(record_id),
  //   root_id,
  //   start_date,
  //   ...queryTable,
  // };
  //
  // const { data, isLoading } = useQueryAdaptor(
  //   ['fetchNeighboursGeometry', params],
  //   fetchNeighboursGeometry,
  // );
  // const neighbors = data ? data.data : [];
  // const count = data ? data.count : 0;
  // const radius = data ? data.radius : 0;

  const isNeighbours = count > 0;

  const textHeader = isNeighbours
    ? 'Найдены следующие смежные ОГХ и элементы благоустройства:'
    : '';

  return (
    <InfoPanel
      accordion={true}
      isOpen={true}
      textHeader={
        <>
          {textHeader}
          <p>
            <GeometryCSVLink
              fileName={'NeighboursGeometry.csv'}
              objects={neighbors}
            />
          </p>
        </>
      }
      Body={
        <div style={{ height: '420px' }}>
          {isNeighbours ? (
            <>
              <div className={'mb-2 justify-content-end d-flex'}>
                <PrintButton
                  {...{
                    prepareFilters: params,
                    printFn: printNeighboursTable,
                  }}
                />
              </div>
              <TableNeighbours
                neighbors={neighbors}
                count={count}
                isLoading={isLoading}
              />
            </>
          ) : (
            `Смежные объекты на расстоянии до ${radius} м. отсутствуют`
          )}
        </div>
      }
      ButtonsActions={
        <Button variant={'contained'} onClick={closeDialog} color={'info'}>
          Закрыть
        </Button>
      }
    />
  );
};
