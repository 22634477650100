import { post } from 'app/api/crud/post';
import {
  IGeometryNeighboursData,
  IGeometryNeighboursRequest,
} from 'types/api.d';

/**
 * Запрос на получение смежных объектов.
 *
 * @param options - Параметры запроса.
 * @returns Ответ от сервера.
 */
export const fetchNeighboursGeometry = (
  options?: IGeometryNeighboursRequest,
) => {
  if (!options) {
    return Promise.resolve(null);
  }
  return post<IGeometryNeighboursData>('/ogh/geometry/neighbours', options);
};
