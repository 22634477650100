import { printReport } from 'app/pages/registry/ogh/api';
import { useFilterQueryParamsYard } from 'app/pages/registry/ogh/ozn/Ozn/Filter.Ozn';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Setup для Отчеты для Летняя уборка..
 *
 * @returns Setup.
 */
export const useSetupDtCleaningSummerMenuItem = () => {
  const { prepareFilters } = useFilterQueryParamsYard();

  const [sendCleaningSummer, { isLoading: loadingCleaningSummer }] =
    useMutationAdaptor(() => printReport('dt_cleaning_summer')(prepareFilters));

  const [
    sendCleaningSummerWithSummary,
    { isLoading: loadingCleaningSummerWithSummary },
  ] = useMutationAdaptor(() =>
    printReport('old_dt_cleaning_summer_summary')(prepareFilters),
  );

  return {
    loadingCleaningSummer,
    loadingCleaningSummerWithSummary,
    sendCleaningSummer,
    sendCleaningSummerWithSummary,
  };
};
