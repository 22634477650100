import { WithMapContext } from 'app/components/map/withMap';
import { getLengthGeometry } from 'app/pages/cardsOgh/api/helpers/getLengthGeometry';
import { postIntersection } from 'app/pages/cardsOgh/api/postCheckIntersections';
import { PreloadedCardContext } from 'app/pages/cardsOgh/components/PreloadedCard/contextPrelouder';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import { createIntersectionsMessage } from 'app/utils/card/checkGeometry/helpers/createIntersectionsMessage';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useContext } from 'react';

/**
 * Хук на получение функции на проверку пересечений.
 *
 * @returns {object}
 */
export const useIntersections = () => {
  // const { setHasIntersections } = useContext(ContextMapEdit);

  /**
   * Заглушка.
   */
  const setHasIntersections = () => {};
  // const { showAlert } = useContext(ContextDialog);

  /**
   *
   * Заглушка.
   */
  const showAlert = () => {};
  const { drawIntersectionsGeometry } = useContext(WithMapContext);
  const { endPreload } = useContext(PreloadedCardContext);
  const [checkIntersection, data] = useMutationAdaptor(postIntersection, {

    /**
     * OnError.
     *
     * @param {object} root0 - Error.
     * @param {string} root0.message - Message Error.
     */
    onError: ({ message = 'Неизвестная ошибка' }) => {
      endPreload();
      showAlert(message);
    },

    /**
     * OnSuccess.
     *
     * @param {object} response - Ответ.
     * @returns {void}
     */
    onSuccess: (response) => {
      const length = getLengthGeometry(response);
      if (length) {
        showAlert(
          createIntersectionsMessage(
            response.geometry,
            message.errorIntersections,
          ),
        );
        drawIntersectionsGeometry(response.geometry);
        setHasIntersections(response.intersected);
      } else {
        showAlert(message.success);
      }
      endPreload();
    },
  });

  return { checkIntersection, ...data };
};
