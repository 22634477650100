import { ToggleButton } from '../ToggleButton';
import { useSetupCheckGeometryButton } from './useSetup.CheckGeometry.Button';

const content = {
  off: {
    className: 'geom-button check-geometry-off material-icons',
    title: 'Показать пересечения со смежными ОГХ',
  },
  on: {
    className: 'geom-button check-geometry-on material-icons',
    title: 'Скрыть пересечения со смежными ОГХ',
  },
};

/**
 * Кнопка проверки пересечения геометрии  со смежными ОГХ.
 *
 * @returns {JSX.Element}
 */
export const CheckGeometryButton = () => {
  const { checkGeometryButton, hasIntersections, isMapLoaded } =
    useSetupCheckGeometryButton();

  return (
    <ToggleButton
      disabled={!isMapLoaded}
      active={hasIntersections}
      content={content}
      onClick={checkGeometryButton}
    />
  );
};
