import './newsNotes.scss';

import { useGetNewsAll } from 'app/api/hooks/news/useGet.NewsAll';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  ExpandMoreIcon,
} from 'core/uiKit/material-ui';
import { renderA } from 'core/utils/renders/renderA';
import React from 'react';

import { pathNewsNotes } from './path.NewsNotes';

/**
 *
 * Компонент Страница Примечания к оповещениям.
 *
 * @returns {JSX.Element} - Страница Примечания к оповещений.
 */
export const NewsNotes = () => {
  const { data: table } = useGetNewsAll();

  return (
    <Card className={'m-5'}>
      <CardHeader title={pathNewsNotes.name} />
      <CardContent>
        <div className={'news-notes__content'}>
          {table?.map((news) => (
            <Accordion key={RANDOM_NUMBER}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={'panel' + news.id}
              >
                {news.create_date}: <b className={'ms-3'}>{news.title}</b>
              </AccordionSummary>
              <AccordionDetails>
                <pre className={'m-3 news-notes__text'}>
                  {renderA(news.text)}
                </pre>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
