import React from 'react';

import { ToggleButton } from '../ToggleButton';
import { useSetupShowDistrictBoundaries } from './useSetup.ShowDistrictBoundaries';

const content = {
  off: {
    className: 'district-off geom-button ',
    title: 'Показать границы районов',
  },

  on: {
    className: 'district-on geom-button ',
    title: 'Скрыть границы районов',
  },
};

/**
 * Компонент кнопки для edit панели в списке объектов.
 *
 * @returns Компонет кнопки для edit.
 * @example <ShowDistrictBoundaries/>
 */
export const ShowDistrictBoundaries = () => {
  const { hasDistrictBoundaries, checkDistrictBoundaries } =
    useSetupShowDistrictBoundaries();

  return (
    <ToggleButton
      active={hasDistrictBoundaries}
      onClick={checkDistrictBoundaries}
      content={content}
    />
  );
};
