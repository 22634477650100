import { MenuItem } from 'core/uiKit/components/Menu';
import { CircularProgressMUI, Icon } from 'core/uiKit/material-ui';
import React from 'react';

import { useSetupDtFullMenuItem } from './useSetup.DtFull.MenuItem';

/**
 *
 * Отчеты для Полный реестр.
 *
 * @returns MenuItem.
 */
export const DtFullMenuItem = () => {
  const { sendFull, loadingFull } = useSetupDtFullMenuItem();

  return (
    // @ts-ignore
    <MenuItem disabled={loadingFull} onClick={sendFull}>
      <span className={'mr-2'}>
        {loadingFull ? <CircularProgressMUI size={20} /> : <Icon>print</Icon>}
      </span>
      Полный реестр
    </MenuItem>
  );
};
