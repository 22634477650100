import { post } from 'app/api/crud/post';
import { IGeometryChildrenData } from 'types/api';

/**
 * Загрузка геометрии дочерних элементов.
 *
 * @param [data] - Данные.
 * @param [data.par_id] - ID родительского элемента.
 * @returns Загруженные данные.
 */
export const loadAllChildrenGeometry = (data?: {
  par_id?: number | string;
}) => {
  if (!data) {
    return Promise.resolve([]);
  }
  return post<IGeometryChildrenData[]>('/ogh/geometry/load', data);
};
