import { RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { MountingModeIdField } from './MountingModeId.Field';
import { useSetupMountingModeIdField } from './useSetup.MountingModeId.Field';

vi.mock('./useSetup.MountingModeId.Field');

describe('🐛 MountingModeIdField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useSetupMountingModeIdField.mockReturnValue({
      disabled: false,
      filter: Function,
    });

    // 🔥 Act
    const wrapper = shallow(<MountingModeIdField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<MountingModeIdRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useSetupMountingModeIdField.mockReturnValue({
      disabled: false,
      filter: Function,
    });

    // 🔥 Act
    const wrapper = shallow(<MountingModeIdField someProp={RANDOM_WORD} />);

    // ❓ Assert
    const mountingModeIdRF = wrapper.find('MountingModeIdRF');

    expect(mountingModeIdRF.prop('disabled')).toBe(false);
    expect(mountingModeIdRF.prop('filter')).toEqual(expect.any(Function));
    expect(mountingModeIdRF.prop('required')).toBe(true);
    expect(mountingModeIdRF.prop('someProp')).toBe(RANDOM_WORD);
  });
});
