import { IconButton } from 'core/uiKit/material-ui';
import { DeleteIcon } from 'core/uiKit/material-ui';
import React from 'react';

import { useSetupDeleteSelectedButton } from './useSetup.DeleteSelected.Button';

interface DeleteSelectedButtonProps {
  disabled: boolean;
}

/**
 * Компонент DeleteSelectedButton.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.disabled - Флаг редактирования.
 * @returns Кнопка.
 */
export const DeleteSelectedButton = ({
  disabled,
}: DeleteSelectedButtonProps) => {
  const { deleteGeometry } = useSetupDeleteSelectedButton();

  return (
    <IconButton
      className={'boxArea button-toggle'}
      disabled={disabled}
      title="Удалить объект с карты"
      onClick={deleteGeometry}
    >
      <DeleteIcon />
    </IconButton>
  );
};
