import { SERVIS_MAP } from 'config/env';
import { Alert } from 'core/uiKit/material-ui';
import { isDevelopment } from 'core/utils/getEnvironment';
import React from 'react';

/**
 * Плашка для показа что включена новая карта.
 *
 * @returns JSX.
 */
export const NewMapAlert = () => {
  const isShow = SERVIS_MAP === 'dtw' && isDevelopment();

  return isShow ? (
    <Alert variant="filled" severity="error">
      Внимание: Включена Новая карта Цифрового Двойника.
    </Alert>
  ) : null;
};
