import React from 'react';

/**
 * Иконка "Скачать геометрию".
 *
 * @returns {JSX.Element}
 */
export const IconDownloadGeometryButton = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.5"
        y="1.5"
        width="16"
        height="19"
        fill="url(#paint0_linear_207_37)"
        stroke="white"
      />
      <rect
        x="5.5"
        y="0.5"
        width="18"
        height="21"
        stroke="#6A6A6A"
        strokeDasharray="2 2"
      />
      <path
        d="M1.23298 13.9353L16.0144 5.48329L11.6076 21.9304L8.27349 15.8331L8.17593 15.6547L7.97854 15.6058L1.23298 13.9353Z"
        fill="white"
        stroke="#1D1D27"
        strokeWidth="0.926042"
      />
      <rect x="15" y="13" width="11" height="6" fill="#5BB8E1" />
      <path
        d="M17 17.0623V14.9968C17 14.6756 17.0784 14.4292 17.2353 14.2575C17.3922 14.0858 17.6239 14 17.9303 14C18.2367 14 18.4684 14.0858 18.6253 14.2575C18.7822 14.4292 18.8606 14.6756 18.8606 14.9968V15.3844H18.2709V14.958C18.2709 14.814 18.2413 14.7134 18.1823 14.6562C18.1232 14.5971 18.0448 14.5676 17.9469 14.5676C17.8491 14.5676 17.7706 14.5971 17.7116 14.6562C17.6525 14.7134 17.623 14.814 17.623 14.958V17.101C17.623 17.245 17.6525 17.3456 17.7116 17.4028C17.7706 17.46 17.8491 17.4887 17.9469 17.4887C18.0448 17.4887 18.1232 17.46 18.1823 17.4028C18.2413 17.3456 18.2709 17.245 18.2709 17.101V16.5334H18.8606V17.0623C18.8606 17.3834 18.7822 17.6299 18.6253 17.8015C18.4684 17.9732 18.2367 18.059 17.9303 18.059C17.6239 18.059 17.3922 17.9732 17.2353 17.8015C17.0784 17.6299 17 17.3834 17 17.0623Z"
        fill="black"
      />
      <path
        d="M19.5413 17.0623V16.7909H20.131V17.101C20.131 17.245 20.1597 17.3456 20.2169 17.4028C20.2759 17.46 20.3553 17.4887 20.455 17.4887C20.5528 17.4887 20.6303 17.46 20.6876 17.4028C20.7466 17.3456 20.7762 17.245 20.7762 17.101C20.7762 16.9644 20.7457 16.8445 20.6848 16.7411C20.6257 16.6377 20.551 16.5399 20.4605 16.4476C20.3701 16.3553 20.2722 16.263 20.167 16.1707C20.0637 16.0766 19.9668 15.9751 19.8763 15.8662C19.7859 15.7554 19.7102 15.6299 19.6493 15.4896C19.5884 15.3493 19.5579 15.185 19.5579 14.9968C19.5579 14.6756 19.6345 14.4292 19.7877 14.2575C19.9409 14.0858 20.1707 14 20.4771 14C20.7835 14 21.0134 14.0858 21.1666 14.2575C21.3198 14.4292 21.3964 14.6756 21.3964 14.9968V15.1518H20.8066V14.958C20.8066 14.814 20.7789 14.7134 20.7236 14.6562C20.6682 14.5971 20.5916 14.5676 20.4938 14.5676C20.3959 14.5676 20.3193 14.5971 20.2639 14.6562C20.2086 14.7134 20.1809 14.814 20.1809 14.958C20.1809 15.0946 20.2113 15.2146 20.2722 15.3179C20.3332 15.4213 20.4088 15.5201 20.4993 15.6142C20.5897 15.7065 20.6866 15.7988 20.79 15.8911C20.8952 15.9834 20.9931 16.0849 21.0835 16.1956C21.1739 16.3045 21.2496 16.4291 21.3105 16.5694C21.3715 16.7097 21.4019 16.874 21.4019 17.0623C21.4019 17.3834 21.3235 17.6299 21.1666 17.8015C21.0097 17.9732 20.778 18.059 20.4716 18.059C20.1652 18.059 19.9335 17.9732 19.7766 17.8015C19.6197 17.6299 19.5413 17.3834 19.5413 17.0623Z"
        fill="black"
      />
      <path
        d="M22.0438 14.0443H22.6723L23.1486 17.2838L23.6248 14.0443H24.1979L23.586 18.0147H22.6557L22.0438 14.0443Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_207_37"
          x1="14.5"
          y1="1"
          x2="14.5"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0C2C4" />
          <stop offset="1" stopColor="#DEDFE0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
