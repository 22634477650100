import {
  AddButtonOgh,
  Body,
  DialogCreateWithDXF,
  Header,
  Main,
  RegistryToolbar,
} from 'app/pages/components';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { TreePanelYard } from 'app/pages/registry/ogh/yard/TreePanelYard';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';

import { FilterYard } from './Filter.Yard';
import { OldReportsYard } from './Old.Reports.Yard';
import { pathYard } from './path.Yard';
import { PrintButtonYard } from './PrintButton.Yard';
import { ReportsYard } from './Reports.Yard';
import { TableYard } from './Table.Yard';

/**
 * Компонент __Дворовые территории__.
 *
 * @returns {JSX.Element}
 */
const Yard = () => {
  const oghType = useGetOghType('yard');
  const typeId = oghType.id;
  const path = `/${pathRegistry.path}/${pathOgh.path}/${pathYard.path}`;

  return typeId ? (
    <Main>
      <Header Filter={FilterYard} />
      <Body>
        <RegistryToolbar showToggle={true} title={oghType.name}>
          <AddButtonOgh typeCode={oghType.code} />
          <PrintButtonYard typeId={typeId} />
          <ReportsYard typeId={typeId} />
          <OldReportsYard typeId={typeId} />
        </RegistryToolbar>
        <div className={'d-flex h-100 overflow-hidden-x'}>
          <TreePanelYard root={path} />
          <TableYard typeId={typeId} />
        </div>
        <DialogCreateWithDXF typeId={typeId} />
      </Body>
    </Main>
  ) : null;
};

export default Yard;
