import './DialogAlert.scss';

import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  actionText: PropTypes.string,
  children: PropTypes.node,
  onAfterHiding: PropTypes.func,
  showCondition: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const defaultProps = {
  actionText: 'Закрыть',
};

/**
 * DialogAlert.
 *
 */
class DialogAlert extends React.Component {

  /**
   * Constructor.
   *
   * @param {*} props - Properties.
   * @returns {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      show: Boolean(props.showCondition),
    };
  }

  /**
   * ComponentDidUpdate.
   *
   * @param {*} prevProps - A.
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { showCondition } = this.props;
    if (prevProps.showCondition !== showCondition && showCondition === true) {
      this.setState({
        show: true,
      });
    }
  }

  /**
   * A.
   *
   * @returns {void}
   */
  handleHide = () => {
    const { onAfterHiding } = this.props;
    this.setState(
      {
        show: false,
      },
      () => {
        if (isFunction(onAfterHiding)) {
          onAfterHiding();
        }
      },
    );
  };

  /**
   * A.
   *
   * @returns {JSX.Element|null}
   */
  render() {
    const { actionText, children, title, maxWidth } = this.props;
    const { show } = this.state;

    return show ? (
      <Dialog
        ButtonsActions={
          <CloseButton title={actionText} onClick={this.handleHide} />
        }
        className="Dialog-Alert"
        isOpen={show}
        textHeader={title}
        maxWidth={maxWidth}
        Body={<pre className="dialog_alert_pre">{children}</pre>}
      />
    ) : null;
  }
}

DialogAlert.propTypes = propTypes;
DialogAlert.defaultProps = defaultProps;

/**
 * A.
 *
 * @param {*} props - The props.
 * @param {*} props.title - D.
 * @param {*} props.onClick - A.
 * @returns {JSX.Element}
 */
function CloseButton({ title, onClick }) {
  return (
    <Button variant={'contained'} color={'primary'} onClick={onClick}>
      {title}
    </Button>
  );
}

export default DialogAlert;
