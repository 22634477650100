import React from 'react';

import { ListItemYard } from '../../ListItemYard';
import { MenuItemYard } from '../../MenuItemYard';
import { useSetupDtCleaningWinterMenuItem } from './useSetup.DtCleaningWinter.MenuItem';

/**
 *
 * Отчеты для Зимняя уборка.
 *
 * @returns MenuItem.
 */
export const DtCleaningWinterMenuItem = () => {
  const {
    sendWinterCleaning,
    sendWinterCleaningWithSummary,
    loadingWinterCleaning,
    loadingWinterCleaningWithSummary,
  } = useSetupDtCleaningWinterMenuItem();

  return (
    <MenuItemYard text={'Зимняя уборка'}>
      <ListItemYard
        text={'Сводный'}
        onClick={sendWinterCleaningWithSummary}
        loading={loadingWinterCleaningWithSummary}
      />
      <ListItemYard
        text={'Подробный'}
        onClick={sendWinterCleaning}
        loading={loadingWinterCleaning}
      />
    </MenuItemYard>
  );
};
