import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const MOUNTING_MODE_ID_NAME = 'mounting_mode_id';

/**
 * ## Жизненная форма.
 *
 * @param {*} props - The props.
 * @param {string} [props.label] - Лейбл.
 * @returns {JSX.Element}
 */
export const MountingModeId = ({ label = 'Тип установки', ...props }) => {
  const dict = 'mounting_mode';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      {...{
        label,
        name: MOUNTING_MODE_ID_NAME,
        options: data,
      }}
    />
  );
};
