import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';

/**
 * Setup Field Select RF __Тип велопарковки__.
 *
 * @returns
 */
export const useSetupBikeParkTypeIdField = () => {
  const parent = useGetParentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  /**
   * Функция фильтрации опшинов.
   *
   * @param dict - Опшины.
   * @returns
   */
  const filter = (
    dict: {
      name: string;
      parent_ogh_object_type_list: string[];
      id: number;
    }[],
  ) => {
    return (dict || []).filter((item) =>
      item.parent_ogh_object_type_list.includes(String(parent.typeId)),
    );
  };

  const disabled = !(editMode && isEditCurrentObjectIsExternalSystem);

  return { disabled, filter };
};
