import { EquipmentTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/EquipmentTypeId.RF';

import { useSetupEquipmentTypeIdField } from './useSetup.EquipmentTypeId.Field';

/**
 * EquipmentTypeIdField.
 *
 * @param {object} props - Props.
 * @returns {JSX.Element}
 */
export const EquipmentTypeIdField = (props) => {
  const { disabled, filter } = useSetupEquipmentTypeIdField();
  return (
    <EquipmentTypeIdRF
      {...props}
      filter={filter}
      disabled={disabled}
      required={true}
    />
  );
};
