import { WithMapContext } from 'app/components/map/withMap';
import { getLengthGeometry } from 'app/pages/cardsOgh/api/helpers/getLengthGeometry';
import { postGeometryContains } from 'app/pages/cardsOgh/api/postCheckGeometryContains';
import { PreloadedCardContext } from 'app/pages/cardsOgh/components/PreloadedCard/contextPrelouder';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import { useIntersections } from 'app/pages/cardsOgh/hooks/useIntersection';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';
import { useContext } from 'react';

/**
 * A.
 *
 * @returns {*}
 */
export const useContains = () => {
  // const { showAlert } = useContext(ContextDialog);
  const showAlert = log.info;
  const { drawIntersectionsGeometry } = useContext(WithMapContext);
  const { endPreload } = useContext(PreloadedCardContext);

  const { checkIntersection } = useIntersections();

  const mutation = useMutationAdaptor(postGeometryContains, {

    /**
     * OnError.
     */
    onError: () => {
      log.info('checkGeometryContains не прошел');
      endPreload();
    },

    /**
     * OnSuccess.
     *
     * @param {object} response - Ответ.
     * @param {object} req - Запрос.
     * @returns {void}
     */
    onSuccess: (response, req) => {
      const length = getLengthGeometry(response);
      if (10 < length) {
        showAlert(message.errorMore);
        endPreload();
      } else if (length) {
        endPreload();
        showAlert(message.errorOther);
        drawIntersectionsGeometry(response.geometry);
      } else checkIntersection(req);
    },
  });

  return { checkGeometryContains: mutation.mutate, dataContains: mutation };
};
