import { IGeometryChildrenData } from 'types/api.d';

import { GeoJSONCoordinatesFormatter } from '..';

/**
 * Форматирование ответа для карты DTW.
 *
 * @param value - Данные.
 * @returns Форматирование данные.
 */
export function formatResponseToGeoJSON(value: IGeometryChildrenData) {
  return {
    ...value,
    lines:
      value.lines?.map((line) => ({
        ...line,
        coordinates: GeoJSONCoordinatesFormatter.polylineToGeoJSON(
          line.coordinates,
        ),
      })) || null,
    points:
      value.points?.map((point) => ({
        ...point,
        coordinates: GeoJSONCoordinatesFormatter.pointToGeoJSON(
          point.coordinates,
        ),
      })) || null,
    polygons:
      value.polygons?.map((polygon) => ({
        ...polygon,
        coordinates: GeoJSONCoordinatesFormatter.polygonToGeoJSON(
          polygon.coordinates,
        ),
      })) || null,
  };
}
