// import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { Prompt } from 'core/uiKit/components/Prompt';
import React from 'react';

// import { useCheckCard } from './useCheckCard';

/**
 * Компонент Подтверждения перейти на другой урл при режиме редактирования.
 *
 * @returns Prompt.
 */
export const PromptEditMode = () => {
  // const { editMode, loading, clearEditMode } = useMode();
  // const isCard = useCheckCard();

  return (
    <Prompt
      when={false}
      message="Все несохраненные изменения будут потеряны."
      // send={clearEditMode}
    />
  );
};
