import './Item.FileList.KnowledgeBase.scss';

import cn from 'classnames';
import { ODS_API_URL } from 'config/env';
import { Button } from 'core/uiKit/components/buttons';
import {
  DownloadIcon,
  FolderIcon,
  Icon,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'core/uiKit/material-ui';
import React from 'react';

import { useSetupItemFileListKnowledgeBase } from './useSetup.Item.FileList.KnowledgeBase';

interface ItemFileListKnowledgeBaseProps {
  file: { file_id: string; file_name: string };

  /**
   * Флаг редактирования.
   */
  disabled?: boolean;
  deleteFile: (fileId: string) => void;
  key: string;
}

/**
 * Список файлов с удалением.
 *
 * @param props - Пропсы.
 * @param props.deleteFile - Функция удаления файла.
 * @param props.disabled - Флаг редактирования.
 * @param props.file - Файл.
 * @param props.key - Ключ.
 * @returns ItemFileListKnowledgeBase.
 */
export const ItemFileListKnowledgeBase = ({
  file,
  deleteFile,
  disabled,
  key,
}: ItemFileListKnowledgeBaseProps) => {
  const { isOpenDialog, openDialog, isVideoFile, closeDialog, delFile } =
    useSetupItemFileListKnowledgeBase({ deleteFile, file });

  return (
    <>
      {isOpenDialog ? (
        <dialog open={true} className="item_file_list_knowledge_base__dialog">
          <div className="d-flex justify-content-end">
            <Button onClick={closeDialog}>Закрыть</Button>
          </div>
          <video controls className="item_file_list_knowledge_base__video">
            <source
              src={`${ODS_API_URL}/files/download/${file?.file_id}`}
              type="video/mp4"
            />
          </video>
        </dialog>
      ) : null}
      <ListItem key={key}>
        <ListItemIcon>
          <FolderIcon />
          <a href={`${ODS_API_URL}/files/download/${file?.file_id}`} download>
            <DownloadIcon />
          </a>
        </ListItemIcon>
        <ListItemText>
          <span
            onClick={openDialog}
            className={cn(' item_file_list_knowledge_base__name', {
              'item_file_list_knowledge_base__name--video': isVideoFile,
            })}
          >
            {file?.file_name}
          </span>
        </ListItemText>
        {disabled ? null : (
          <IconButton
            data-testId={`deleteIconList-${file?.file_id}`}
            className={''}
            onClick={delFile}
            title="Удалить файл"
            size={'small'}
          >
            <Icon color={'action'}>delete</Icon>
          </IconButton>
        )}
      </ListItem>
    </>
  );
};
