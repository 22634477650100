import { List } from 'core/uiKit/material-ui';
import React from 'react';

import { ItemFileListKnowledgeBase } from '../Item.FileList.KnowledgeBase';

interface File {
  file_id: string;
  file_name: string;
}

interface FileListInterface {
  value: { file_id: string; file_name: string }[];

  /**
   * Флаг редактирования.
   */
  disabled?: boolean;
  onChange: (files: File[]) => void;
}

/**
 * Список файлов с удалением.
 *
 * @param {FileListInterface} props - Пропсы.
 * @param props.files - Файлы.
 * @class
 */
export const FileListKnowledgeBase = ({
  value: files,
  disabled,
  onChange,
}: FileListInterface) => {

  /**
   * Функция удаления файла по id.
   *
   * @param fileId - Id файла.
   */
  const deleteFile = (fileId: string) => {
    const fileList = files.filter(({ file_id }) => file_id !== fileId);
    onChange(fileList);
  };

  return (
    <List dense>
      {(files || []).map((item) => (
        <ItemFileListKnowledgeBase
          key={item.file_id}
          file={item}
          disabled={disabled}
          deleteFile={deleteFile}
        />
      ))}
    </List>
  );
};
