import { useState } from 'react';

/**
 * Сетап к компоненту ReportsYard.
 *
 * @returns {JSX.Element}
 */
export const useSetupReportsYard = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * Функция handleClick.
   *
   * @param {Event} event - Event.
   * @returns {void}
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Функция handleClose.
   *
   * @returns {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
  };
  return {
    anchorEl,
    handleClick,
    handleClose,
    setAnchorEl,
  };
};
