import './PanelRightCard.scss';

import DrawButton from 'app/components/card/common/MapEditPanel/buttons/DrawButton';
import { HOLE, LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';
import { CheckGeometryButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/CheckGeometry.Button';
import { ReonButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ReonButton';
import { ShowChildrenButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowChildrenButton';
import { ShowDistrictBoundaries } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowDistrictBoundaries/ShowDistrictBoundaries';
// import { GeometryTypes } from 'core/uiKit/components/DTW/contexts/utils/types.d';
import React from 'react';

import { DeleteSelectedButton } from './buttons/DeleteSelected.Button';
import { DownloadGeometryButton } from './buttons/DownloadGeomety.Button';
import { NeighboursButton } from './buttons/Neighbours.Button/Neighbours.Button';
import { useSetupPanelRightCard } from './useSetup.PanelRightCard';

/**
 *Компонент PanelRightCard.
 *
 * @param {{allowedGeometryTypes: object}} props - Жопа.
 * @returns {JSX.Element} - JSX.
 * @example
 * const MapEditPanelWrapper = (props) => {
 *   const allowedGeometryTypes = useGetAllowedGeometryTypes();
 *
 *   return <PanelRightCard allowedGeometryTypes={allowedGeometryTypes} />;
 * };
 */
export const PanelRightCard = ({ allowedGeometryTypes }) => {
  const {
    activeGeometry,
    context,
    currentCard,
    editMode,
    getGeometry,
    lineAllowed,
    onDrawClick,
    pointAllowed,
    polygonAllowed,
  } = useSetupPanelRightCard({ allowedGeometryTypes });

  return (
    <div className="map-edit__card">
      <ShowDistrictBoundaries />
      <DownloadGeometryButton
        currentCard={currentCard}
        getGeometry={getGeometry}
        isMapLoaded={context?.isMapLoaded}
      />
      <NeighboursButton />
      <ReonButton />
      <ShowChildrenButton />
      <CheckGeometryButton />
      <DrawButton
        active={activeGeometry === POINT}
        disabled={!editMode || !pointAllowed}
        geometryType={POINT}
        onClick={onDrawClick}
      />
      <DrawButton
        active={activeGeometry === LINESTRING}
        disabled={!editMode || !lineAllowed}
        geometryType={LINESTRING}
        onClick={onDrawClick}
      />
      <DrawButton
        active={activeGeometry === POLYGON}
        disabled={!editMode || !polygonAllowed}
        geometryType={POLYGON}
        onClick={onDrawClick}
      />
      <DrawButton
        active={activeGeometry === HOLE}
        disabled={!editMode || !polygonAllowed}
        geometryType={HOLE}
        onClick={onDrawClick}
      />
      <DeleteSelectedButton
        callMapFunction={context?.callMapFunction}
        currentCard={currentCard}
        disabled={!editMode}
      />
    </div>
  );
};
