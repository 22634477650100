import { DatePickerFF } from 'core/form/finalForm/fields/default/datePickers/DatePickerFF';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF';

/**
 * Тело диалогового окна таблицы Проектов.
 *
 * @returns {JSX.Element}
 */
export const BodyDialogRepairsInfoListPlan = () => {
  return (
    <>
      <TextFieldFF name={'name'} label={'Наименование'} required={true} />
      <DatePickerFF
        name={'date_start'}
        label={'Дата начала работ'}
        required={true}
        inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
      />
      <DatePickerFF
        name={'date_end'}
        label={'Дата окончания работ'}
        required={true}
        inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
      />
      <TextFieldFF
        name={'property'}
        label={'Техническое задание (характеристика объемы)'}
      />
      <DndFileUploadFF
        required={true}
        multiple={false}
        text={
          'Переместите файл технического задания / проекта благоустройства или нажмите для выбора'
        }
      />
    </>
  );
};
