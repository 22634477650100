import {
  validateCleanArea,
  validateNumeric,
} from 'app/components/card/common/validation';
import { numericFields } from 'app/components/card/ogh/PlanarStructureCard/numerlicFields';

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
export function validate(values) {
  return {
    ...validateNumeric(values, numericFields),
    ...validateCleanArea(values),
  };
}
