import './Panel.scss';

import { DeleteSelectedButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/DeleteSelected.Button';
import { DownloadGeometryButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/DownloadGeomety.Button';
import { NeighboursButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/Neighbours.Button/Neighbours.Button';
import { ReonButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ReonButton';
import { ShowChildrenButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowChildrenButton';
import { ShowDistrictBoundaries } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowDistrictBoundaries/ShowDistrictBoundaries';
import { GeometryTypes } from 'core/uiKit/components/DTW/contexts/utils/types.d';
import React from 'react';

import { CheckGeometryButton } from './buttons/CheckGeometry.Button';
import DrawButton from './buttons/DrawButton';
import { useSetupMapEditPanelDTW } from './useSetup.MapEditPanel.DTW';

/**
 * Панель редактирования карты.
 *
 * @param props - Параметры.
 * @param props.editable - Редактируемая карта.
 * @param props.getGeometry - Функция получения геометрии.
 * @param props.lineAllowed - Доступна ли линия.
 * @param props.onDrawClick - Обработчик клика по кнопке рисования.
 * @param props.pointAllowed - Доступна ли точка.
 * @param props.polygonAllowed - Доступна ли полигон.
 * @param props.currentCard - Текущая карта.
 * @param props.activeGeometryType - Тип активного геометрического объекта.
 * @param props.isMapLoaded - Загружена ли карта.
 * @returns JSX.
 */
export const CompMapEditPanel = ({
  activeGeometryType,
  isMapLoaded,
  currentCard,
  editable,
  getGeometry,
  lineAllowed,
  onDrawClick,
  pointAllowed,
  polygonAllowed,
}: ReturnType<typeof useSetupMapEditPanelDTW>) => {
  return (
    <div className="map-edit">
      <ShowDistrictBoundaries />
      <DownloadGeometryButton
        currentCard={currentCard}
        getGeometry={getGeometry}
        isMapLoaded={isMapLoaded}
      />
      <NeighboursButton />
      <ReonButton />
      {/* <Dialogs> */}
      <ShowChildrenButton />
      {/* </Dialogs> */}
      <CheckGeometryButton />
      <DrawButton
        active={activeGeometryType === GeometryTypes.Point}
        disabled={!editable || !pointAllowed}
        geometryType={GeometryTypes.Point}
        onClick={onDrawClick}
      />
      <DrawButton
        active={activeGeometryType === GeometryTypes.Polyline}
        disabled={!editable || !lineAllowed}
        geometryType={GeometryTypes.Polyline}
        onClick={onDrawClick}
      />
      <DrawButton
        active={activeGeometryType === GeometryTypes.Polygon}
        disabled={!editable || !polygonAllowed}
        geometryType={GeometryTypes.Polygon}
        onClick={onDrawClick}
      />
      <DrawButton
        active={activeGeometryType === GeometryTypes.Hole}
        disabled={!editable || !polygonAllowed}
        geometryType={GeometryTypes.Hole}
        onClick={onDrawClick}
      />
      <DeleteSelectedButton disabled={!editable} />
    </div>
  );
};
