import { unstable_useBlocker as useBlocker } from 'react-router-dom';

interface IuseSetupPrompt {
  when: boolean;
  send?: () => void;
}

/**
 * Используется для подсказки пользователю перед уходом со страницы.
 *
 * @param {IuseSetupPrompt} props - Properties.
 * @returns React component.
 */
export const useSetupPrompt = ({ when, send }: IuseSetupPrompt) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (when) {
      return currentLocation.pathname !== nextLocation.pathname;
    }

    return false;
  });

  /**
   * Функция закрыть диалоговое окно.
   */
  const closeDialog = () => {
    if (typeof blocker?.reset === 'function') {
      blocker.reset();
    }
  };

  /**
   * Функция запуска продолжения.
   */
  const sendHandler = () => {
    if (typeof blocker?.reset === 'function') {
      blocker.proceed();
      if (typeof send === 'function') {
        send();
      }
    }
  };

  const isShow = blocker.state === 'blocked';

  return { closeDialog, isShow, sendHandler };
};
