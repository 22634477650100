import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { ResponseGeometryObjects } from 'core/uiKit/components/DTW/contexts/utils/types.d';
import { useState } from 'react';

/**
 * Хук для работы с пересечениями Reon для контекса.
 *
 * @returns Методы.
 */
export const useReonIntersections = () => {
  const [reonIntersectionsMap, setReonIntersectionsMap] = useState<
    Map<string | number, ResponseGeometryObjects | ResponseGeometryObjects[]>
  >(new Map());

  const currentCard = useGetCurrentCard();

  /**
   * Получить пересечение РЕОН текущей карточки.
   *
   * @returns Флаг пересечения.
   */
  const getReonIntersections = () => {
    return reonIntersectionsMap.get(currentCard?.record_id);
  };

  /**
   * Установить пересечение РЕОН для текущей карточки.
   *
   * @param flag - Флаг пересечения.
   */
  const setReonIntersections = (
    flag: ResponseGeometryObjects | ResponseGeometryObjects[],
  ) => {
    reonIntersectionsMap.set(currentCard?.record_id, flag);
    setReonIntersectionsMap(new Map(reonIntersectionsMap));
  };
  return {
    getReonIntersections,
    hasReonIntersections: getReonIntersections(),
    setReonIntersections,
  };
};
