import { useState } from 'react';

import { usePrintReportOld } from '../api';
import { useFilterQueryParamsYard } from '../Filter.Yard';

/**
 * Сетап к компоненту ReportsYard.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const useSetupOldReportsYard = ({ typeId }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { prepareFilters } = useFilterQueryParamsYard();

  /**
   * Функция handleClick.
   *
   * @param {Event} event - Event.
   * @returns {void}
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Функция handleClose.
   *
   * @returns {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    sendSummerCleaning,
    loadingSummerCleaning,
    sendSummerCleaningWithSummary,
    loadingSummerCleaningWithSummary,
    sendWinterCleaning,
    loadingWinterCleaning,
    sendWinterCleaningWithSummary,
    loadingWinterCleaningWithSummary,
    sendDtClassification,
    loadingDtClassification,
    sendDtClassificationWithSummary,
    loadingDtClassificationWithSummary,
    sendMaf,
    loadingMaf,
    sendMafWithSummary,
    loadingMafWithSummary,
    sendGreening,
    loadingGreening,
    sendGreeningWithSummary,
    loadingGreeningWithSummary,

    sendCover,
    loadingCover,
    sendCoverWithSummary,
    loadingCoverWithSummary,
    sendDt,
    loadingDt,
    sendDtWithSummary,
    loadingDtWithSummary,
  } = usePrintReportOld(typeId);

  return {
    anchorEl,
    handleClick,
    handleClose,
    loadingCover,
    loadingCoverWithSummary,
    loadingDt,
    loadingDtClassification,
    loadingDtClassificationWithSummary,
    loadingDtWithSummary,
    loadingGreening,
    loadingGreeningWithSummary,
    loadingMaf,
    loadingMafWithSummary,
    loadingSummerCleaning,
    loadingSummerCleaningWithSummary,
    loadingWinterCleaning,
    loadingWinterCleaningWithSummary,
    prepareFilters,
    sendCover,
    sendCoverWithSummary,
    sendDt,
    sendDtClassification,
    sendDtClassificationWithSummary,
    sendDtWithSummary,
    sendGreening,
    sendGreeningWithSummary,
    sendMaf,
    sendMafWithSummary,
    sendSummerCleaning,
    sendSummerCleaningWithSummary,
    sendWinterCleaning,
    sendWinterCleaningWithSummary,
  };
};
