import React from 'react';

import { CompMapEditPanel } from './Comp.MapEditPanel';
import { useSetupMapEditPanelDTW } from './useSetup.MapEditPanel.DTW';

/**
 * Панель редактирования карты.
 *
 * @returns JSX.
 */
export const MapEditPanelDTW = () => {
  return <CompMapEditPanel {...useSetupMapEditPanelDTW()} />;
};
