import { printReport } from 'app/pages/registry/ogh/api';
import { useFilterQueryParamsYard } from 'app/pages/registry/ogh/ozn/Ozn/Filter.Ozn';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 *
 * Setup for Отчеты для Зимняя уборка.
 *
 * @returns Setup.
 */
export const useSetupDtCleaningWinterMenuItem = () => {
  const { prepareFilters } = useFilterQueryParamsYard();

  const [sendWinterCleaning, { isLoading: loadingWinterCleaning }] =
    useMutationAdaptor(() => printReport('dt_cleaning_winter')(prepareFilters));

  const [
    sendWinterCleaningWithSummary,
    { isLoading: loadingWinterCleaningWithSummary },
  ] = useMutationAdaptor(() =>
    printReport('dt_cleaning_winter_summary')(prepareFilters),
  );

  return {
    loadingWinterCleaning,
    loadingWinterCleaningWithSummary,
    sendWinterCleaning,
    sendWinterCleaningWithSummary,
  };
};
