import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';

/**
 * Компонент DeleteSelectedButton.
 *
 * @returns Методы.
 */
export const useSetupDeleteSelectedButtonEgip = () => {
  const context = useMapContext();
  const currentCard = useGetCurrentCard();

  /**
   * Удалить геометрию.
   */
  const deleteGeometry = () => {
    context.callMapFunction('deleteSelected', currentCard);
  };

  return {
    deleteGeometry,
  };
};
