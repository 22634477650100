import { fetchOghTypes } from 'core/uiKit/preparedInputs/api';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук получения опшинов __Тип велопарковки__.
 *
 * @returns
 */
export const useGetOptionsBikeParkTypeId = () => {
  const { data = [] } = useQueryAdaptor(
    ['optionsBikeParkTypeId', { typeId: 29 }],
    fetchOghTypes,
  );

  return data;
};
