import { cancelLeaveCurrentElement } from 'app/actions/odsObjectActions';
import { useDispatch, useSelector } from 'react-redux';
import { BooleanParam, useQueryParams, withDefault } from 'use-query-params';

/**
 * Хук, проверки состояния редактирования из url или redux.
 *
 * @returns {{editMode:boolean,clearEditMode: ()=> void,startEditMode:Function, viewMode: boolean, loading: boolean, startLoading: Function}}
 */
export const useMode = () => {
  const modeRedux = useSelector((state) => {
    return state?.card?.mode;
  });
  const [query, setQuery] = useQueryParams({
    edit: BooleanParam,
  });
  const [{ loading }, setLoading] = useQueryParams({
    loading: withDefault(BooleanParam, false),
  });

  /**
   * Функция старт ожидания.
   *
   * @returns {void}
   */
  const startLoading = () => {
    setLoading({
      loading: true,
    });
  };

  /**
   * Функция конец ожидания.
   *
   * @returns {void}
   */
  const endLoading = () => {
    setLoading({
      loading: undefined,
    });
  };

  const dispatch = useDispatch();

  /**
   * Очистка состояния.
   *
   * @returns {void}
   */
  const clearEditMode = () => {
    setQuery({
      edit: undefined,
    });
    dispatch(cancelLeaveCurrentElement());
  };

  /**
   * Старт редактирования.
   *
   * @returns {void}
   */
  const startEditMode = () => {
    setQuery({
      edit: true,
    });
  };
  return {
    clearEditMode,
    editMode: modeRedux?.editMode || !!query.edit,
    endLoading,
    loading,
    startEditMode,
    startLoading,
    viewMode: modeRedux?.viewMode || !query.edit,
  };
};
