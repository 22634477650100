import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const EQUIPMENT_TYPE_ID_NAME = 'equipment_type_id';

/**
 * ## Жизненная форма.
 *
 * @param {*} props - The props.
 * @param {string} [props.label] - Лейбл.
 * @returns {JSX.Element}
 */
export const EquipmentTypeId = ({ label = 'Тип', ...props }) => {
  const dict = 'equipment_type';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      {...{
        label,
        name: EQUIPMENT_TYPE_ID_NAME,
        options: data,
      }}
    />
  );
};
