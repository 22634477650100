import './tableSimple.scss';

import { ReactTableBodySkeleton } from 'core/uiKit/components/skeletons/ReactTableBodySkeleton';
import {
  CheckboxMUI,
  SortIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useRowSelect, useTable } from 'react-table';

import { preparationColumns } from './preparationColumns';

/**
 * A.
 *
 * @param {boolean} flag - Flag.
 * @returns {{textOrientation: string, marginTop: number, writingMode: string}|{}}
 */
const textVertical = (flag) => {
  return flag
    ? {
        marginTop: 12,
        textOrientation: 'sideways',
        writingMode: 'vertical-lr',
      }
    : {};
};

/**
 * A.
 *
 * @param {boolean} flag - Flag.
 * @returns {{verticalAlign: string, textAlign: string}|{}}
 */
const textVerticalCell = (flag) => {
  return flag
    ? {
        textAlign: 'center',
        verticalAlign: 'top',
      }
    : {};
};

/**
 * @typedef {import('./types.Table.d').Columns} Columns - Массив Cell.
 */

/**
 * Компонент таблицы с пагинацией.
 *
 * @param {object} props - Пропсы.
 * @param {Columns} props.columns - Колонки.
 * @param {Array} props.data - Массив таблицы.
 * @param {boolean} [props.isLoading] - Флаг таблицы.
 * @param {Function} [props.selectedRows] - Функция выбора колонок.
 * @param {boolean} [props.checkboxSelection] - Флаг checkboxSelection.
 * @param {string} [props.nameSort] - Name sorting.
 * @param {string} [props.direction] - Направление сортировки.
 * @param {*} [props.sortHandler] - A.
 * @param {*} [props.disabledSelectRow] - A.
 * @param {*} [props.initialValueSelectRow] - S.
 * @returns {JSX.Element}
 */
const TableSimple = ({
  columns,
  data = [],
  isLoading,
  nameSort,
  direction,
  sortHandler = (_) => _,
  selectedRows,
  checkboxSelection,
  disabledSelectRow,
  initialValueSelectRow,
}) => {
  const memoData = useMemo(() => data.filter(Boolean), [data]);
  const memoColumns = useMemo(() => preparationColumns(columns), [columns]);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
    toggleRowSelected,
  } = useTable(
    {
      columns: memoColumns,
      // columns: columns,
      data: memoData,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) =>
        [
          // Let's make a column for selection
          checkboxSelection && {
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox

            /**
             * Renders a checkbox for row selection.
             *
             * @param {object} root0 - The root object.
             * @param {object} root0.row - The row object.
             * @returns {JSX.Element} - The rendered checkbox.
             */
            Cell: ({ row }) => {
              return (
                <CheckboxMUI
                  color="primary"
                  {...row.getToggleRowSelectedProps()}
                  disabled={disabledSelectRow}
                  className={'p-0'}
                  inputProps={{
                    'aria-label': 'table checkbox_' + row.id,
                    'data-testid': 'table_checkbox_' + row.id,
                    title: 'Выбрать строку',
                  }}
                />
              );
            },
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox

            /**
             * Renders a checkbox for selecting all rows.
             *
             * @param {object} root0 - The root object.
             * @param {Function} root0.getToggleAllRowsSelectedProps - Function to get props for selecting all rows.
             * @returns {JSX.Element} - The rendered checkbox.
             */
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckboxMUI
                color="primary"
                {...getToggleAllRowsSelectedProps()}
                disabled={disabledSelectRow}
                className={'p-0'}
                inputProps={{
                  title: 'Выбрать все строки',
                }}
              />
            ),
            align: 'center',
            id: 'selection',
            minWidth: 20,
          },
          ...columns,
        ].filter((item) => item),
      );
    },
  );

  useEffect(() => {
    memoData?.forEach(({ id }, idx) => {
      if (initialValueSelectRow?.includes(id)) {
        initialValueSelectRow?.forEach(() => toggleRowSelected(idx, true));
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeof selectedRows === 'function') {
      selectedRows({ selectedFlatRows, selectedRowIds });
    }
    // eslint-disable-next-line
  }, [Object.keys(selectedRowIds).length, selectedFlatRows.length]);
  return (
    <Table {...getTableProps()} size="small">
      <TableHead className={'table_stick-header'}>
        {headerGroups.map((headerGroup) => (
          <TableRow
            {...headerGroup.getHeaderGroupProps()}
            className={'table__header'}
          >
            {headerGroup.headers.map((column) => {
              return column.hideHeader ? null : (
                <TableCell
                  align={column.align || 'center'}
                  style={{
                    minWidth: column.minWidth || '100px',
                    ...textVerticalCell(column.textVertical),
                  }}
                  {...column.getHeaderProps()}
                  rowSpan={column.rowSpan}
                  className={'table_stick-header__th'}
                >
                  <span
                    style={textVertical(column.textVertical)}
                    className={'table_stick-header__span'}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={nameSort === (column?.originalId || column.id)}
                        hideSortIcon={true}
                        direction={direction}
                        IconComponent={SortIcon}
                        onClick={() =>
                          sortHandler(column?.originalId || column.id)
                        }
                      >
                        {column.render('Header')}
                      </TableSortLabel>
                    ) : (
                      column.render('Header')
                    )}
                  </span>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      {isLoading ? (
        <ReactTableBodySkeleton columns={columns} countRows={10} />
      ) : (
        <TableBody>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                className={`${row?.original?.trClassName} table-simple__row`}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      align={cell.column.align || 'center'}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </Table>
  );
};

export default TableSimple;

TableSimple.propTypes = {

  /**
   *
   * Флаг показать чекбокс выбора заданых по умолчанию.
   */
  checkboxSelection: PropTypes.bool,

  /**
   * Данные для формирования заголовка таблицы и колонок.
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({

      /**
       *
       * Переопределение ячейки таблицы.
       */
      Cell: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func,
      ]),

      /**
       * Вывод этого в качестве заголовка.
       */
      Header: PropTypes.string,

      /**
       * Имя поля по которому формируется столбец.
       */
      accessor: PropTypes.string,

      /**
       * Расположение текста
       * Установите выравнивание текста для содержимого ячейки таблицы.
       * Денежные или числовые поля должны быть выровнены по правому краю,
       * так как это позволяет вам быстро складывать их в уме, не беспокоясь о десятичных дробях.
       */
      align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),

      /**
       * Минимальная ширина столбца по дефолту 100px.
       */
      minWidth: PropTypes.string,

      /**
       * Устанавливает число ячеек, которые должны быть объединены по вертикали.
       * Этот атрибут имеет смысл для таблиц, состоящих из нескольких строк.
       */
      rowSpan: PropTypes.number,

      /**
       * Разрешает сортировку по столбцу.
       */
      sortable: PropTypes.bool,
    }),
  ),

  /**
   * Сами данные таблицы.
   */
  data: PropTypes.arrayOf(PropTypes.object),

  /**
   * Направление сортировки.
   */
  direction: PropTypes.oneOf(['desc', 'asc']),

  /**
   * Имя столбца (по accessor) по которому произходит сортировка.
   */
  nameSort: PropTypes.string,

  /**
   * Функция для выбора чекбоксом строчек таблицы
   * на вход параметры selectedRowIds {object} and selectedFlatRows {array}
   * ({selectedRowIds, selectedFlatRows })=>_.
   */
  selectedRows: PropTypes.func,

  /**
   * Функция сортировки. Срабатывает при клике по названию столбца.
   */
  sortHandler: PropTypes.func,
};
