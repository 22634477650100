import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { NEIGHBOURS } from 'app/constants/layers';
import { useState } from 'react';

import { createObjectForMap } from '../helpers/createObjectForMap';
import { useGetNeighboursMuttation } from './useGetNeighbours.Muttation';

/**
 * Setup Кнопка показать смежные ОГХ для Эгип.
 *
 * @returns Данные для отрисовки компонента.
 */
export const useSetupNeighboursButtonEgip = () => {
  const { drawNeighboursGeometry, getGeometry, clearLayer, isMapLoaded } =
    useMapContext();
  const { root_id, start_date, end_date, recordId } = useGetCurrentCard();

  const [hasNeighbours, setHasNeighbours] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { count, getNeighbours, isLoading, neighbors, radius } =
    useGetNeighboursMuttation({
      //

      /**
       * Обработчик успешного завершения запроса.
       *
       * @param data - Данные с бэка.
       */
      onSuccess: (data) => {
        const neighbours = data?.data;
        if (neighbours && neighbours.length > 0) {
          const items = neighbours.map((item) => createObjectForMap(item));
          drawNeighboursGeometry(items);
          setHasNeighbours(true);
          setIsOpen(true);
        }
      },
    });

  /**
   * Функция получения показа таблицы смежных ОГХ и отрисовки геометрии.
   *
   */
  const handleClickOpen = async () => {
    if (hasNeighbours) {
      clearLayer(NEIGHBOURS);
      setHasNeighbours(false);
    } else {
      const data = {
        end_date,
        geometry: getGeometry(recordId),
        root_id,
        start_date,
      };

      // @ts-ignore
      getNeighbours(data);

      setIsOpen(true);
    }
  };

  /**
   * Функция закрыть диалоговое окно.
   *
   */
  const closeDialog = () => {
    setIsOpen(false);
  };

  return {
    closeDialog,
    count,
    handleClickOpen,
    hasNeighbours,
    isLoading,
    isMapLoaded,
    isOpen: isOpen && !isLoading,
    neighbors,
    params: {
      end_date,
      geometry: getGeometry(recordId),
      root_id,
      start_date,
    },
    radius,
  };
};
