import getHint from 'app/utils/getHint';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { OdhCard } from './OdhCard';

vi.mock('dateformat', () => ({
  DateFormat: vi.fn(),
}));

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', () => ({
  ...vi.importActual('react-redux'),
  connect: vi.fn(() => (ba) => {
    return ba;
  }),
}));

describe('Odh', function () {
  it('default', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      calcAttributes: {
        inbound_area: '',
      },
      card: {
        name: '',
        parent_info: {},
      },
      currentValues: { is_orphan_object: false },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: true,
      mode: {
        disabled: false,
      },
    };
    // Act
    const wrapper = shallow(<OdhCard {...props} />);

    // Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });

  it('TabsOdh', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      calcAttributes: {
        inbound_area: '',
      },
      card: {
        name: '',
        parent_info: {},
      },
      currentValues: { is_orphan_object: false },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: true,
      mode: {
        disabled: false,
      },
      onPropertyTab: 'onPropertyTab',
      propertyTab: 'propertyTab',
    };
    // Act
    const wrapper = shallow(<OdhCard {...props} />);

    // Assert
    expect(wrapper.find('CardContainer').find('TabsOdh').props()).toStrictEqual(
      {
        onPropertyTab: 'onPropertyTab',
        propertyTab: 'propertyTab',
      },
    );
  });

  it('children tabs', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      calcAttributes: {
        inbound_area: '',
      },
      card: {
        name: '',
        parent_info: {},
      },
      currentValues: { is_orphan_object: false },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: false,
      mode: {
        disabled: false,
      },
      propertyTab: 'propertyTab',
    };
    // Act
    const wrapper = shallow(<OdhCard {...props} />);

    // Assert
    expect(wrapper.find('BasicParameters').prop('propertyTab')).toBe(
      'propertyTab',
    );
    expect(wrapper.find('IsOrphanObjectRfField').length).toBe(1);
    expect(wrapper.find('Cleaning').prop('propertyTab')).toBe('propertyTab');
    expect(wrapper.find('MovementCharacteristics').prop('propertyTab')).toBe(
      'propertyTab',
    );
    expect(wrapper.find('EngineeringStructuresTab').prop('propertyTab')).toBe(
      'propertyTab',
    );
  });

  it('field OwnerIdOdhRF disabled', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      calcAttributes: {
        inbound_area: '',
      },
      card: {
        name: '',
        parent_info: {},
        record_id: RANDOM_NUMBER,
      },
      currentValues: { is_orphan_object: false },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: true,
      mode: {
        disabled: false,
      },
      onPropertyTab: 'onPropertyTab',
      propertyTab: 'propertyTab',
    };
    // Act
    const wrapper = shallow(<OdhCard {...props} />);

    // Assert
    expect(wrapper.find('OwnerIdOdhRF').props()).toStrictEqual({
      disabled: true,
      helpTooltip: getHint('owner_id'),
      required: true,
    });
  });

  it.each`
    isOrphanObject | editMode | isEditCurrentObjectIsExternalSystem | expDisabled
    ${false}       | ${true}  | ${true}                             | ${false}
    ${false}       | ${false} | ${true}                             | ${true}
    ${false}       | ${true}  | ${false}                            | ${true}
  `(
    'field passport_draft_org isOrphanObject=$isOrphanObject, editMode=$editMode, isEditCurrentObjectIsExternalSystem=$isEditCurrentObjectIsExternalSystem, expDisabled=$expDisabled',
    ({
      isOrphanObject,
      editMode,
      isEditCurrentObjectIsExternalSystem,
      expDisabled,
    }) => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const props = {
        calcAttributes: {
          inbound_area: '',
        },
        card: {
          name: '',
          parent_info: {},
          record_id: RANDOM_NUMBER,
        },
        currentValues: { is_orphan_object: isOrphanObject },
        editMode,
        isEditCurrentObjectIsExternalSystem,
        mode: {
          disabled: false,
        },
        onPropertyTab: 'onPropertyTab',
        propertyTab: 'propertyTab',
      };
      // Act
      const wrapper = shallow(<OdhCard {...props} />);

      const passport_draft_org = wrapper.findWhere(
        (item) => item.prop('name') === 'passport_draft_org',
      );

      // Assert
      expect(passport_draft_org.props()).toStrictEqual({
        dark: false,
        disabled: expDisabled,
        label:
          'Исполнитель/Исполнители работ (по разработке, актуализации паспорта)',
        name: 'passport_draft_org',
        required: true,
      });
    },
  );
  it('showIfIsNotOrphanObject passport_draft_org', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      calcAttributes: {
        inbound_area: '',
      },
      card: {
        name: '',
        parent_info: {},
        record_id: RANDOM_NUMBER,
      },
      currentValues: { is_orphan_object: true },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: true,
      mode: {
        disabled: false,
      },
      onPropertyTab: 'onPropertyTab',
      propertyTab: 'propertyTab',
    };
    // Act
    const wrapper = shallow(<OdhCard {...props} />);

    const passport_draft_org = wrapper.findWhere(
      (item) => item.prop('name') === 'passport_draft_org',
    );

    // Assert
    expect(passport_draft_org.length).toStrictEqual(0);
  });
});
