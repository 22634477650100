import { useMapContext } from 'app/components/map/useMapContext';
import toArray from 'app/utils/toArray';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';

import { createObjectForMapEgip } from '../helpers/createObjectForMapEgip';
import { postDistrictBoundaries } from './postDistrictBoundaries';

/**
 * Хук для получения геометрии и описания.
 *
 * @param {boolean} setHasDistrictBoundaries - Функция изменения состояния hasDistrictBoundaries.
 * @returns {{getDistrictBoundaries: Function}} - Метод для получения геометрии и описания.
 * @example
 *  const [hasDistrictBoundaries, setHasDistrictBoundaries] = useState(false);
 *
 *   const { getDistrictBoundaries } = useGetDistrictBoundaries(
 *     setHasDistrictBoundaries,
 *   );
 */
export const useGetDistrictBoundariesEgip = (setHasDistrictBoundaries) => {
  const { drawDistrictBoundaries } = useMapContext();
  const [getDistrictBoundaries] = useMutationAdaptor(postDistrictBoundaries, {
    //

    /**
     * OnError.
     *
     */
    onError: () => {
      log.info('postDistrictBoundaries не прошел');
      toast.infoDying('Пересечения отсутствуют');
    },

    /**
     * OnSuccess.
     *
     * @param {object} data - Данные.
     */
    onSuccess: (data) => {
      const intersections = toArray(data);
      if (intersections.length > 0) {
        const items = intersections.map(createObjectForMapEgip);
        drawDistrictBoundaries(items);
        setHasDistrictBoundaries(true);
      }
    },
  });

  return { getDistrictBoundaries };
};
