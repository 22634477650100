import { useMapContext } from 'app/components/map/useMapContext';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';

import { loadAllChildrenGeometry } from './loadAllChildrenGeometry';

/**
 * Загрузка геометрии дочерних элементов.
 *
 * @returns Загруженные данные.
 */
export const useGetDrawAllChildrenGeometryMutationDTW = () => {
  const { drawAllChildrenGeometryWithFormat } = useMapContext();

  const [getGeometryLoad, { isLoading }] = useMutationAdaptor(
    loadAllChildrenGeometry,
    {

      /**
       * Error.
       *
       * @param error - Error.
       */
      onError: (error) => {
        log.error(error);
        log.info('Данные не пришли useGetGeometryLoadMutation');
      },

      /**
       * Success.
       *
       * @param data - Data.
       */
      onSuccess: (data) => {
        drawAllChildrenGeometryWithFormat(data);
      },
    },
  );

  return { getGeometryLoad, isLoading };
};
