import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  MOUNTING_MODE_ID_NAME,
  MountingModeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/MountingModeId';

/**
 * Select Field RF Тип установки.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const MountingModeIdRF = (props) => {
  return (
    <FieldRF
      {...props}
      name={MOUNTING_MODE_ID_NAME}
      component={MountingModeId}
    />
  );
};
