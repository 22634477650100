import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { REON } from 'app/constants/layers';
import React from 'react';

import { useGetReonIntersectionsEgip } from './useGetReonIntersections/useGetReonIntersections.Egip';
import { useReonIntersections } from './useReonIntersections';

/**
 * Кнопка Показать пересечения с данными ИС РЕОН с алертом.
 *
 * @param {object} props - Пропсы.
 * @param {(message: string)=> void} props.showAlert - Функция вызова Алерта.
 * @returns {React.ReactElement}
 */
export const useSetupReonButtonWithAlertEgip = ({ showAlert }) => {
  const { clearLayer, getGeometry, isMapLoaded } = useMapContext();
  const { hasReonIntersections, setReonIntersections } = useReonIntersections();

  const { recordId } = useGetCurrentCard();
  const { getReonIntersections, isLoading } = useGetReonIntersectionsEgip({
    setReonIntersections,
    showAlert,
  });

  /**
   * Функция checkGeometryReonByButton.
   *
   */
  const checkGeometryReonByButton = () => {
    if (hasReonIntersections) {
      clearLayer(REON);
      setReonIntersections(false);
    } else {
      const data = {
        geometry: getGeometry(recordId),
      };
      getReonIntersections(data);
    }
  };

  return {
    checkGeometryReonByButton,
    hasReonIntersections,
    isLoading,
    isMapLoaded,
  };
};
