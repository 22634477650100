import { printReport } from 'app/pages/registry/ogh/api';
import { useFilterQueryParamsYard } from 'app/pages/registry/ogh/ozn/Ozn/Filter.Ozn';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 *
 * Setup for Отчеты для Покрытие и элементы сопряжения.
 *
 * @returns Setup.
 */
export const useSetupDtCoverMenuItem = () => {
  const { prepareFilters } = useFilterQueryParamsYard();

  const [sendCover, { isLoading: loadingCover }] = useMutationAdaptor(() =>
    printReport('dt_cover')(prepareFilters),
  );

  const [sendCoverWithSummary, { isLoading: loadingCoverWithSummary }] =
    useMutationAdaptor(() => printReport('dt_cover_summary')(prepareFilters));

  return {
    loadingCover,
    loadingCoverWithSummary,
    sendCover,
    sendCoverWithSummary,
  };
};
