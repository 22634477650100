import { useMapglEditorContext } from 'core/uiKit/components/DTW/contexts/MapglEditorContextProvider';

/**
 * Компонент DeleteSelectedButton.
 *
 * @returns Кнопка.
 */
export const useSetupDeleteSelectedButtonDTW = () => {
  const context = useMapglEditorContext();

  /**
   * DeleteGeometry.
   *
   */
  const deleteGeometry = () => {
    // @ts-ignore
    context.deleteGeometry();
  };

  return {
    deleteGeometry,
  };
};
