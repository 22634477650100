import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { getParseAttribute } from 'app/components/card/common/getParseAttribute';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { CLEANING_TYPE_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import PlanarStructureCard, {
  mapStateToProps,
  onSubmit,
} from './planar_structure';

vi.mock('app/components/card/common/getParseAttribute');
vi.mock('app/components/card/common/validation');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock('./submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('PlanarStructureCard', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'PlanarStructureCard',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,

      mode: {},
    };
    useGetParentCard.mockReturnValue({ typeId: 1 });
    // 🔥 Act

    const wrapper = shallow(<PlanarStructureCard {...props} />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<PlanarStructureCard />');
  });
});

describe('🐛 onSubmit', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 31 } };
    const onSubmitProps = onSubmit.bind({ props });
    // 🔥 Act

    onSubmitProps();
    // ❓ Assert
    expect(1).toBe(1);
  });
});

describe('🐛 PlanarStructureSpec', () => {
  it('🧪 init parse', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const parseSpy = vi.fn();
    getParseAttribute.mockReturnValue(parseSpy);
    // 🔥 Act
    mapStateToProps(
      {
        tree: {
          object: { coating_group: [] },
        },
      },
      {
        card: {
          coating_group_id: 1,
          end_date: '2021-01-01',
        },
      },
    );

    // ❓ Assert
    expect(parseSpy).toHaveBeenCalledWith('abutment_type_list');
    expect(parseSpy).toHaveBeenCalledWith(
      'planar_structure_type_id.planar_structure_type',
    );
    expect(parseSpy).toHaveBeenCalledWith(CLEANING_TYPE_NAME);
    expect(parseSpy).toHaveBeenCalledWith('coating_group_id.coating_group');
    expect(parseSpy).toHaveBeenCalledWith('coating_type_id.coating_type');
    expect(parseSpy).toHaveBeenCalledWith('is_diff_height_mark');
    expect(parseSpy).toHaveBeenCalledWith('no_calc');
    expect(parseSpy).toHaveBeenCalledWith('total_area_geo');
    expect(parseSpy).toHaveBeenCalledWith('property');
    expect(parseSpy).toHaveBeenCalledWith('total_area');
    expect(parseSpy).toHaveBeenCalledWith('total_area_geo');
  });
});
