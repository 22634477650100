import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { CHILDREN } from 'app/constants/layers';
import { useGetGeometryLoadMutation } from 'app/pages/cardsOgh/components/Map/api/geometryLoad';
import { useState } from 'react';

import { useDialogShowChildrenButton } from '../useDialog.ShowChildrenButton';

/**
 * Hook useShowChildrenButton.
 *
 * @returns JSX.
 */
export const useSetupShowChildrenButtonEgip = () => {
  const [active, setActive] = useState(false);
  const { recordId, children } = useGetParentCard();
  const { getGeometryLoad } = useGetGeometryLoadMutation();
  const { clearLayer, isMapLoaded } = useMapContext();
  const { isOpen, closeDialog, openDialog } = useDialogShowChildrenButton();

  /**
   * Функция отображения геометрии.
   *
   * @returns Promise<void>.
   */
  const showChildrenByButton = async () => {
    if (children) {
      if (active) {
        clearLayer(CHILDREN);
        setActive(false);
      } else {
        await getGeometryLoad({ par_id: recordId });
        setActive(true);
      }
    } else {
      openDialog();
    }
  };

  return {
    active,
    closeDialog,
    isLoading: false,
    isMapLoaded,
    isOpen,
    showChildrenByButton,
  };
};
