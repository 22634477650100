import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';

const SPR_NUMBER = 'spr20';

/**
 * Setup EquipmentTypeIdField.
 *
 * @returns {object}
 */
export const useSetupEquipmentTypeIdField = () => {
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  /**
   * Функция фильтрации для equipment_type.
   *
   * @param {Array} dict - Данные для фильтрации.
   * @returns {Array} Отфильтрованные данные.
   */
  const filter = (dict) => {
    return dict.filter((item) => item.fz_spr.includes(SPR_NUMBER));
  };

  const disabled = !(editMode && isEditCurrentObjectIsExternalSystem);

  return { disabled, filter };
};
