import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  EQUIPMENT_TYPE_ID_NAME,
  EquipmentTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/EquipmentTypeId';

/**
 * Select Field RF Тип установки.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const EquipmentTypeIdRF = (props) => {
  return (
    <FieldRF
      {...props}
      name={EQUIPMENT_TYPE_ID_NAME}
      component={EquipmentTypeId}
    />
  );
};
