import React from 'react';

import { ToggleButton } from '../ToggleButton';
import { DialogShowChildrenButton } from './Dialog.ShowChildrenButton';
import { useSetupShowChildrenButton } from './useSetupShowChildrenButton';

const content = {
  off: {
    className: 'geom-button show-children-off material-icons',
    title: 'Показать геометрию элементов текущего ОГХ',
  },

  on: {
    className: 'geom-button show-children-on material-icons',
    title: 'Скрыть геометрию элементов текущего ОГХ',
  },
};

/**
 * Кнопка отображения геометрии дочерних элементов.
 *
 * @returns {JSX.Element}
 */
export const ShowChildrenButton = () => {
  const {
    active,
    isLoading,
    closeDialog,
    isMapLoaded,
    isOpen,
    showChildrenByButton,
  } = useSetupShowChildrenButton();

  return (
    <>
      <ToggleButton
        disabled={!isMapLoaded}
        active={active}
        content={content}
        isLoading={isLoading}
        onClick={showChildrenByButton}
      />
      <DialogShowChildrenButton isOpen={isOpen} closeDialog={closeDialog} />
    </>
  );
};
