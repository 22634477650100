import {
  HOLE,
  LINESTRING,
  POINT,
  POLYGON,
  POLYLINE,
} from 'app/constants/geometryTypes';

export default {
  [HOLE]: {
    drawFunction: 'hole',
    icon: 'content_cut',
    title: 'Вырезать полигон',
  },

  [LINESTRING]: {
    drawFunction: 'line',
    icon: 'remove',
    title: 'Нарисовать линию',
  },
  [POINT]: {
    drawFunction: 'point',
    icon: 'add_location',
    title: 'Нарисовать точку',
  },
  [POLYGON]: {
    drawFunction: 'polygon',
    icon: 'crop_landscape',
    title: 'Нарисовать полигон',
  },
  [POLYLINE]: {
    drawFunction: 'line',
    icon: 'remove',
    title: 'Нарисовать линию',
  },
};
