import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  BIKE_PARK_TYPE_ID_NAME,
  BikeParkTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/BikeParkTypeId';
import React from 'react';
import { SelectPropsWithRedux } from 'types/inputs';

/**
 * Select RF __Тип велопарковки__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const BikeParkTypeIdRF = (props: SelectPropsWithRedux) => {
  return (
    <FieldRF
      {...props}
      component={BikeParkTypeId}
      name={BIKE_PARK_TYPE_ID_NAME}
    />
  );
};
