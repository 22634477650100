import { MenuItem } from 'core/uiKit/components/Menu';
import {
  Collapse,
  ExpandLessIcon,
  ExpandMoreIcon,
  List,
} from 'core/uiKit/material-ui';
import React, { ForwardedRef, ReactNode, useState } from 'react';

interface MenuItemYardProps {
  text: string;
  children: ReactNode;
}

/**
 * Компонент MenuItemYard.
 *
 * @returns {JSX.Element}
 */
export const MenuItemYard = React.forwardRef(
  (
    { text, children }: MenuItemYardProps,
    ref: ForwardedRef<HTMLDivElement>, // Тип для ссылки
  ) => {
    const [open, setOpen] = useState(false);

    /**
     * Функция handleOpen.
     *
     * @returns {void}
     */
    const handleOpen = () => setOpen(!open);

    return (
      <>
        {/*  @ts-ignore */}
        <MenuItem onClick={handleOpen} ref={ref}>
          <span className={'mr-2'}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </span>
          {text}
        </MenuItem>
        <Collapse in={open}>
          <List>{children}</List>
        </Collapse>
      </>
    );
  },
);
