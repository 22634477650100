import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 * @typedef {import('src/core/uiKit/components/tables/Table/Table').Columns} Columns
 */

/**
 * Получение Columns __Иные некапитальные объекты__.
 *
 * @returns {Columns} - Жопа.
 * @example ---
 */
export const getColumnsOtherTechPlace = () => [
  cellsMainMap.short_root_id, // ID
  cellsMainMap.object_type_name, // Тип
  cellsMainMap.buildings_type_spec_name, // Уточнение типа
  cellsMainMap.address_name, // Адрес
  cellsMainMap.unom, // UNOM
  cellsMainMap.unad, // UNAD
  cellsMainMap.is_actual, // Статус адреса БТИ
  cellsMainMap.building_klas, // Класс строения
  cellsMainMap.object_status_name, // Статус
  cellsMainMap.sign_date, // Дата подписания
  cellsMainMap.sign_person, // ФИО подписанта
  cellsMainMap.start_date, // Дата начала
  cellsMainMap.end_date, // Дата окончания
  cellsMainMap.parent_object_name, // Наименование родительского объекта
  cellsMainMap.parent_short_root_id, // Идентификатор родительского объекта
  cellsMainMap.parent_okrug_name, // Округ родительского объекта
  cellsMainMap.parent_owner_name, // Балансодержатель родительского объекта
  cellsMainMap.parent_grbs_name, // ГРБС
  cellsMainMap.area, // Площадь, кв.м
  cellsMainMap.total_area_geo, // Площадь объекта по пространственным координатам, кв.м
  cellsMainMap.material_name, // Материал
  cellsMainMap.is_diff_height_mark, // Разновысотные отметки
  cellsMainMap.included_in_tl,
];
