import React from 'react';

import { ListItemYard } from '../../ListItemYard';
import { MenuItemYard } from '../../MenuItemYard';
import { useSetupDtMafMenuItem } from './useSetup.DtMaf.MenuItem';

/**
 *
 * Отчеты для МАФы.
 *
 * @returns MenuItem.
 */
export const DtMafMenuItem = () => {
  const { sendMaf, sendMafWithSummary, loadingMaf, loadingMafWithSummary } =
    useSetupDtMafMenuItem();

  return (
    <MenuItemYard text={'МАФы'}>
      <ListItemYard
        text={'Сводный'}
        onClick={sendMafWithSummary}
        loading={loadingMafWithSummary}
      />
      <ListItemYard text={'Подробный'} onClick={sendMaf} loading={loadingMaf} />
    </MenuItemYard>
  );
};
