import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { useState } from 'react';

import { useDialogShowChildrenButton } from '../useDialog.ShowChildrenButton';
import { useGetDrawAllChildrenGeometryMutationDTW } from './useGetDrawAllChildrenGeometryMutation.DTW';

/**
 * Hook useShowChildrenButton.
 *
 * @returns JSX.
 */
export const useSetupShowChildrenButtonDTW = () => {
  const [active, setActive] = useState(false);
  const { recordId, children } = useGetParentCard();
  const { getGeometryLoad, isLoading } =
    useGetDrawAllChildrenGeometryMutationDTW();
  const { mapService } = useMapContext();
  const { isOpen, closeDialog, openDialog } = useDialogShowChildrenButton();

  /**
   * Функция отображения геометрии.
   *
   * @returns Promise<void>.
   */
  const showChildrenByButton = async () => {
    if (children) {
      if (active) {
        getGeometryLoad(undefined);
        setActive(false);
      } else {
        getGeometryLoad({ par_id: recordId });
        setActive(true);
      }
    } else {
      openDialog();
    }
  };

  return {
    active,
    closeDialog,
    isLoading,
    isMapLoaded: mapService?.isMapLoaded,
    isOpen,
    showChildrenByButton,
  };
};
