import React from 'react';

import { ListItemYard } from '../../ListItemYard';
import { MenuItemYard } from '../../MenuItemYard';
import { useSetupDtCleaningSummerMenuItem } from './useSetup.DtCleaningSummer.MenuItem';

/**
 * Отчеты для Летняя уборка.
 *
 * @returns MenuItem.
 */
export const DtCleaningSummerMenuItem = () => {
  const {
    loadingCleaningSummer,
    loadingCleaningSummerWithSummary,
    sendCleaningSummer,
    sendCleaningSummerWithSummary,
  } = useSetupDtCleaningSummerMenuItem();

  return (
    <MenuItemYard text={'Летняя уборка'}>
      <ListItemYard
        text={'Сводный'}
        onClick={sendCleaningSummerWithSummary}
        loading={loadingCleaningSummerWithSummary}
      />
      <ListItemYard
        text={'Подробный'}
        onClick={sendCleaningSummer}
        loading={loadingCleaningSummer}
      />
    </MenuItemYard>
  );
};
