import React from 'react';

import { ListItemYard } from '../../ListItemYard';
import { MenuItemYard } from '../../MenuItemYard';
import { useSetupDtMenuItem } from './useSetup.Dt.MenuItem';

/**
 *
 * Отчеты для Общий отчёт по форме ТС.
 *
 * @returns MenuItem.
 */
export const DtMenuItem = () => {
  const { sendDt, sendDtWithSummary, loadingDt, loadingDtWithSummary } =
    useSetupDtMenuItem();

  return (
    <MenuItemYard text={'Общий отчёт по форме ТС'}>
      <ListItemYard
        text={'Сводный'}
        onClick={sendDtWithSummary}
        loading={loadingDtWithSummary}
      />
      <ListItemYard text={'Подробный'} onClick={sendDt} loading={loadingDt} />
    </MenuItemYard>
  );
};
