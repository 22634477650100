import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { DatePickerFF } from 'core/form/finalForm/fields/default/datePickers/DatePickerFF';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment/moment';
import React from 'react';

export const GURANTEE_PERIOD_DATE_NAME = 'guarantee_period';

/**
 * Дата __Гарантийный срок__.
 *
 * @returns {JSX.Element}
 */
export const GuranteePeriodDate = () => {
  const { editMode } = useEditMode();

  usePreparation(GURANTEE_PERIOD_DATE_NAME, (value) => {
    let newValue = value;

    if (moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY');
    }
    return newValue;
  });

  return (
    <DatePickerFF
      name={GURANTEE_PERIOD_DATE_NAME}
      label={'Гарантийный срок'}
      disabled={!editMode}
      inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
    />
  );
};
