import './ToggleButton.scss';

import { Button } from 'core/uiKit/components/buttons/Button';
import React from 'react';

import { PreLouder } from './Prelouder';
interface IToggleButtonProps {
  active: boolean;
  content: {
    on: {
      title: string;
      className: string;
    };
    off: {
      title: string;
      className: string;
    };
  };
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

/**
 * Компонент обертка кнопки панели.
 *
 * @param props - Пропсы.
 * @param props.active - Флаг активной иконки.
 * @param props.content - Контент.
 * @param props.onClick - Клик по кнопке.
 * @param props.disabled - Флаг disabled.
 * @param props.isLoading - Загрузка.
 * @returns JSX.Element.
 */
export const ToggleButton = ({
  active,
  content,
  onClick,
  disabled,
  isLoading,
}: IToggleButtonProps) => {
  const current = active ? content?.on : content?.off;
  return (
    <ButtonT
      isLoading={isLoading}
      content={current}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

interface IButtonProps {
  content: {
    title: string;
    className: string;
  };
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

/**
 * Компонент кнопки панели с прелоудером.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.content - Контент.
 * @param {Function} props.onClick - Клик по кнопке.
 * @param {boolean} props.disabled - Флаг disabled.
 * @param {boolean} props.isLoading - Загрузка.
 * @returns JSX.Element.
 */
const ButtonT = ({ content, onClick, disabled, isLoading }: IButtonProps) => {
  const { title, className } = content;

  return (
    <Button
      className={'button-toggle'}
      type="button"
      disabled={disabled}
      toolTip={title}
      onClick={onClick}
    >
      <PreLouder isLoading={isLoading} />
      <i className={className + ' material-icons'} />
    </Button>
  );
};
