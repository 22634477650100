import { preloadData } from 'app/actions/preload';
import _ from 'lodash';
import { handleActions } from 'redux-actions';

const dictsMap = {
  abutmentTypes: 'abutment_type',
  arrangeElementTypes: {
    mapper: mapArrangeElementType,
    name: 'arrange_element_type',
  },
  bikeRefTypes: 'bike_ref_type',
  binTypes: 'bin_type',
  buildingsType: 'buildings_type',
  buildingsTypeSpec: 'buildings_type_spec',
  carEcologyClasses: 'car_ecology_class',
  carEquipmentType: 'car_equipment_type',
  carFuncTypes: 'car_func_type',
  carMotorTypes: 'car_motor_type',
  carOwners: 'car_owners',
  carOwnership: 'car_ownership',
  carSeasons: 'car_season',
  categoryMsops: 'category_msop',
  changeTypes: {
    mapper: mapChangeTypes,
    name: 'change_type',
  },
  coatingType: {
    mapper: mapCoatingType,
    name: 'coating_type',
  },
  containerTypes: {
    mapper: mapContainerType,
    name: 'container_type',
  },
  customers: 'customers',
  departmentTypes: 'department_type',
  districts: 'districts',
  documentStatuses: 'documents_statuses',
  dtsType: {
    mapper: mapDtsType,
    name: 'dts_type',
  },
  dtsTypeSpec: 'dts_type_spec',
  entities: 'entity',
  explicationTypes: 'explication_type',
  founders: 'founder',
  geometryTypes: {
    mapper: mapGeometryTypes,
    name: 'geometry_type',
  },
  gpsStates: 'gps_state',
  grbs: 'grbs',
  lawnTypes: 'lawn_type',
  legalCarRegistration: 'legal_car_registration',
  lifeFormTypes: 'life_form_type',
  mafTypeLevel1: 'maf_type_level1',
  mafTypeLevel2: {
    mapper: mapMafTypeLevel2,
    name: 'maf_type_level2',
  },
  mafTypeLevel3: {
    mapper: mapMafTypeLevel3,
    name: 'maf_type_level3',
  },
  matchingChangeTypes: 'matching_change_type',
  oghFileTypes: {
    mapper: mapOghFileType,
    name: 'ogh_file_type',
  },
  oghGroups: {
    mapper: mapOghGroup,
    name: 'ogh_group',
  },
  oghTypes: 'ogh_types',
  okrugs: 'okrugs',
  ooptCategories: 'oopt_category',
  ooptInterstatuses: 'oopt_interstatus',
  ooptMeanings: 'oopt_meaning',
  ooptProfiles: 'oopt_profile',
  ooptStatuses: 'oopt_status',
  owners: 'owners',
  plantType: 'plant_type',
  plantationTypes: 'plantation_type',
  prefChildren: 'pref_children',
  prefectures: 'prefecture',
  reagentBaseTypes: 'reagent_base_type',
  reagentKinds: 'reagent_kind',
  reagents: 'reagent',
  sensorStates: 'sensor_state',
  stateGardening: 'state_gardening',
  techTypes: 'tech_types',
  technicFileTypes: 'technik_file_types',
  technicRequestReason: 'technic_request_reason',
  technicRequestState: 'technic_request_state',
  technicRequestType: 'technic_request_type',
  units: 'unit',
};
export default handleActions(
  {
    [`${preloadData}_FULFILLED`]: getOdsDicts,
  },
  {
    changeTypes: {},
    customers: {},
    districts: [],
    oghStatuses: {},
    okrugs: [],
    owners: {},
    personRoles: {},
    tlTypes: {},
  },
);

/**
 * Reducer for odsDicts state.
 *
 * @param {object} state - Initial state.
 * @param {object} action - Action object.
 * @returns {object} New state.
 */
function getOdsDicts(state, action) {
  const {
    payload: { odsDicts: dicts },
  } = action;

  const result = {
    ...state,
    carConditions: {
      ..._.mapKeys(dicts.car_condition, (value) => value.id),
    },
    carFuncTypeGroups: {
      ..._.mapKeys(dicts.car_func_type_group, (value) => value.id),
    },
    characteristicStateGardening: {
      ..._.mapKeys(dicts.characteristic_state_gardening, (value) => value.name),
    },
    detailedStateGardening: {
      ..._.mapKeys(dicts.detailed_state_gardening, (value) => value.id),
    },
    equipmentTypes: {
      ..._.mapKeys(dicts.gps_state, (value) => value.id),
    },
    greeneryType: {
      ..._.mapKeys(dicts.greenery_type, (value) => value.id),
    },
    matchingStatus: {
      ..._.mapKeys(dicts.matching_status, (value) => value.id),
    },
    oghStatuses: {
      ..._.mapKeys(dicts.ogh_statuses, (value) => value.id),
    },
    oghTitleReason: {
      ..._.mapKeys(dicts.ogh_title_reason, (value) => value.id),
    },
    otherImprovementObjectType: {
      ..._.mapKeys(dicts.other_improvement_object_type, (value) => value.id),
    },
    personRoles: {
      ..._.mapKeys(dicts.person_role, (value) => value.id),
    },
    plantServiceRecomendations: {
      ..._.mapKeys(dicts.plant_service_recomendations, (value) => value.id),
    },
    reasons: {
      ..._.mapKeys(dicts.reason, (value) => value.id),
      ..._.mapKeys(dicts.reason, (value) => value.id),
    },
    sensorKinds: {
      ..._.mapKeys(dicts.sensor_kind, (value) => value.id),
    },
    sensorTypes: {
      ..._.mapKeys(dicts.sensor_type, (value) => value.id),
    },
    tlTypes: {
      ..._.mapKeys(dicts.tl_types, (value) => value.id),
    },
  };

  return Object.assign(result, extractDicts(dicts));
}

/**
 * Extracts the dictionaries from the given data.
 *
 * @param {object} dicts - The data which contains the dictionaries.
 * @returns {object} A new object with the extracted dictionaries.
 */
function extractDicts(dicts) {
  return Object.entries(dictsMap).reduce((result, [key, value]) => {
    let name;
    let mapper;

    if (_.isObject(value)) {
      name = value.name;
      mapper = value.mapper;
    } else {
      name = value;
    }

    if (_.isFunction(mapper)) {
      result[key] = mapper(dicts[name]);
    } else {
      result[key] = dicts[name];
    }
    return result;
  }, {});
}

/**
 * Maps an array of ogh object types to a new array with modified structure.
 *
 * @param {Array} dict - The array of ogh object types to map.
 * @returns {Array} A new array where each object is transformed to include only the original properties and an updated 'ogh_object_type_id' property.
 */
function mapDtsType(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: { name, parent_ogh_object_type_list },
    } = item;
    return {
      id,
      name,
      ogh_object_type_list: mapOghOjectTypeList(parent_ogh_object_type_list),
    };
  });
}

/**
 * Maps an array of DtsType objects to a new array with modified structure.
 *
 * @param {Array} dict - The array of DtsType objects to map.
 * @returns {Array} A new array where each object is transformed to include only the original properties and an updated 'ogh_object_type_list' property.
 */
export function mapCoatingType(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: { name, class_id, coating_group_id, ogh_object_type_list },
    } = item;
    return {
      class_id: class_id.class,
      group_id: coating_group_id.coating_group,
      id,
      name,
      ogh_object_type_list: mapOghOjectTypeList(ogh_object_type_list),
    };
  });
}

/**
 * Maps an array of container types to a new array with modified structure.
 *
 * @param {Array} dict - The array of container types to map.
 * @returns {Array} A new array where each object is transformed to include only the original properties and an updated 'code' and 'has_separate_garbage_collection' properties.
 */
function mapContainerType(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: { name, code, has_separate_garbage_collection },
    } = item;
    return {
      code,
      has_separate_garbage_collection,
      id,
      name,
    };
  });
}

/**
 * Maps an array of arrange element types to a new array with modified structure.
 *
 * @param {Array} dict - The array of arrange element types to map.
 * @returns {Array} A new array where each object is transformed to include only the original properties and an updated 'ogh_object_type_id' and 'parent_ogh_object_type_list' properties.
 */
function mapArrangeElementType(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: {
        name,
        ogh_object_type_id,
        parent_ogh_object_type_list,
        unit_id,
      },
    } = item;
    return {
      id,
      name,
      ogh_object_type_id: ogh_object_type_id.ogh_object_type,
      parent_ogh_object_type_list: mapOghOjectTypeList(
        parent_ogh_object_type_list,
      ),
      unit_id: unit_id.unit,
    };
  });
}

/**
 * Maps an array of ogh object types to a new array with modified structure.
 *
 * @param {Array} list - The array of ogh object types to map.
 * @returns {Array} A new array where each object is transformed to include only the original properties and an updated 'ogh_object_type_id' property.
 */
function mapOghOjectTypeList(list = []) {
  return list.map((params) => {
    const { ogh_object_type_id, other_improvement_object_type_list } = params;
    if (ogh_object_type_id) {
      return {
        ogh_object_type_id: ogh_object_type_id.ogh_object_type,
        other_improvement_object_type_list: mapOtherImprovementObjectTypeList(
          other_improvement_object_type_list,
        ),
      };
    }
    return params;
  });
}

/**
 * Maps an array of other improvement object types to a new array with modified structure.
 *
 * @param {Array} list - The array of other improvement object types to map.
 * @returns {Array} A new array where each object is transformed to include only the original properties and an updated 'other_improvement_object_type_id' property.
 */
function mapOtherImprovementObjectTypeList(list = []) {
  return list.map(({ other_improvement_object_type_id }) => {
    return other_improvement_object_type_id?.other_improvement_object_type;
  });
}

/**
 * MAF type level 2 mapper.
 *
 * @param {Array} dict - MAF type level 2 list.
 * @returns {Array} MAF type level 2 list.
 */
function mapMafTypeLevel2(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: { name, is_material, maf_type_list = [] },
    } = item;
    return {
      id,
      is_material,
      maf_type_list: maf_type_list.map((item) => {
        return {
          maf_type_level1_id: item.maf_type_level1_id.maf_type_level1,
        };
      }),
      name,
    };
  });
}

/**
 * Справочные значения mapMafTypeLevel3.
 *
 * @param {Array} dict - Справочные значения.
 * @returns {{id: *, name: *, maf_type_level1_id: *, unit_id: *, parent_ogh_object_type_list: *}[]}
 */
function mapMafTypeLevel3(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: {
        name,
        maf_type_level1_id,
        parent_ogh_object_type_list,
        unit_id: { unit } = {},
      },
    } = item;

    const unit_id = unit;

    return {
      id,
      maf_type_level1_id: maf_type_level1_id?.maf_type_level1,
      name,
      parent_ogh_object_type_list,
      unit_id,
    };
  });
}

/**
 * Справочные значения mapMafTypeLevel3.
 *
 * @param {Array} dict - Справочные значения.
 * @returns {{id: *, name: *, maf_type_level1_id: *, unit_id: *, parent_ogh_object_type_list: *}[]} - Массив с отфильтрованными значениями.
 */
function mapChangeTypes(dict = []) {
  return dict.map((item) => ({
    ...item,
    id: item.code,
  }));
}

/**
 * Maps an array of change type objects to a new array with modified structure.
 *
 * @param {Array} dict - The array of change type objects to map.
 * @returns {Array} A new array where each object is transformed to include only the original properties and an updated 'id' property.
 */
function mapOghGroup(dict = []) {
  return dict.map((item) => ({
    code: item.attribute.code,
    id: item.id,
    name: item.attribute.name,
    oghFileTypeList: item.attribute.attribute.ogh_file_type_list_id,
  }));
}

/**
 * Maps an array of OghGroup objects to a new array with modified structure.
 *
 * @param {Array} dict - The array of OghGroup objects to map.
 * @returns {Array} A new array where each object is transformed to include only the original properties and an updated 'id' property.
 */
function mapOghFileType(dict = []) {
  return dict.map((item) => ({
    id: item.id,
    ...item.attribute,
  }));
}

/**
 * Maps an array of OghFileType objects to a simplified structure.
 *
 * @param {Array} dict - The array of OghFileType objects.
 * @returns {Array} A new array of simplified OghFileType objects.
 */
function mapGeometryTypes(dict = []) {
  const result = {};

  dict.map((item) => {
    const id = item.ogh_object_type_id;
    result[id] = item.attribute;
  });
  return result;
}
