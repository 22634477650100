import { post } from 'app/api/crud/post';
import { useMapContext } from 'app/components/map/useMapContext';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import toArray from 'app/utils/toArray';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { createMessage } from './createMessage';
import { createObjectForMapEgip } from './createObjectForMap/createObjectForMap.Egip';

/**
 * Метод postReonIntersection.
 *
 * @param {object} data - Данные.
 * @returns {object}
 */
const postReonIntersection = (data) =>
  post('/ogh/geometry/reon/intersection', data);

/**
 * Хук useGetReonIntersections.
 *
 * @param {object} props - Пропсы.
 * @returns {object}
 */
export const useGetReonIntersectionsEgip = (props) => {
  const { showAlert, setReonIntersections } = props;
  const { drawReonGeometry } = useMapContext();
  const [getReonIntersections] = useMutationAdaptor(postReonIntersection, {
    //

    /**
     * Успешный кейс.
     *
     * @param {object} data - Ответ.
     */
    onSuccess: (data) => {
      const intersections = toArray(data);
      if (intersections.length > 0) {
        showAlert(createMessage(intersections, message.reonIntersections));
        const items = intersections.map((item) => createObjectForMapEgip(item));
        drawReonGeometry(items);
        setReonIntersections(true);
      } else {
        showAlert(message.success);
      }
    },
  });

  return { getReonIntersections };
};
