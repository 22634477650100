import { DtCoverMenuItem } from 'app/pages/registry/ogh/yard/Yard/menuItems.Yard/DtCover.MenuItem';
import { useSetupReportsYard } from 'app/pages/registry/ogh/yard/Yard/Reports.Yard/useSetup.Reports.Yard';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Menu } from 'core/uiKit/components/Menu';
import { ExpandMoreIcon } from 'core/uiKit/material-ui';
import React from 'react';

import { DtMenuItem } from '../menuItems.Yard/Dt.MenuItem';
import { DtClassificationMenuItem } from '../menuItems.Yard/DtClassification.MenuItem';
import { DtCleaningSummerMenuItem } from '../menuItems.Yard/DtCleaningSummer.MenuItem';
import { DtCleaningWinterMenuItem } from '../menuItems.Yard/DtCleaningWinter.MenuItem';
import { DtFullMenuItem } from '../menuItems.Yard/DtFull.MenuItem';
import { DtGreeningMenuItem } from '../menuItems.Yard/DtGreening.MenuItem';
import { DtMafMenuItem } from '../menuItems.Yard/DtMaf.MenuItem';

/**
 * Компонент ReportsYard.
 *
 * @returns {JSX.Element}
 */
export const ReportsYard = () => {
  const { handleClick, handleClose, anchorEl } = useSetupReportsYard();
  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        endIcon={<ExpandMoreIcon />}
      >
        Отчёты (new)
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted={true}
        open={Boolean(anchorEl)}
        PaperProps={{
          style: {
            maxHeight: 450,
          },
        }}
        onClose={handleClose}
      >
        <DtCleaningSummerMenuItem />
        <DtCleaningWinterMenuItem />
        <DtClassificationMenuItem />
        <DtMafMenuItem />
        <DtGreeningMenuItem />
        <DtMenuItem />
        <DtCoverMenuItem />
        <DtFullMenuItem />
      </Menu>
    </>
  );
};
