import './Prelouder.scss';

import React from 'react';

/**
 * Компонент прелоудера ToggleButton.
 *
 * @param props - Пропсы.
 * @param props.isLoading - Флаг загрузки.
 * @returns JSX.Element|null.
 */
export const PreLouder = ({ isLoading }: { isLoading?: boolean }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={'pre-louder__background'}>
      <div className={'pre-louder'}></div>
    </div>
  );
};
