import React from 'react';

import { ListItemYard } from '../../ListItemYard';
import { MenuItemYard } from '../../MenuItemYard';
import { useSetupDtCoverMenuItem } from './useSetup.DtCover.MenuItem';

/**
 *
 * Отчеты для Покрытие и элементы сопряжения.
 *
 * @returns MenuItem.
 */
export const DtCoverMenuItem = () => {
  const {
    sendCover,
    sendCoverWithSummary,
    loadingCover,
    loadingCoverWithSummary,
  } = useSetupDtCoverMenuItem();

  return (
    <MenuItemYard text={'Покрытие и элементы сопряжения'}>
      <ListItemYard
        text={'Сводный'}
        onClick={sendCoverWithSummary}
        loading={loadingCoverWithSummary}
      />
      <ListItemYard
        text={'Подробный'}
        onClick={sendCover}
        loading={loadingCover}
      />
    </MenuItemYard>
  );
};
