import './MapServiceSwitch.scss';

import React from 'react';

import { useSetupMapServiceSwitch } from './useSetup.MapServiceSwitch';

/**
 * Временный компонент переключения сервиса карты.
 *
 * @returns
 */
export const MapServiceSwitch = () => {
  const { onChange, value } = useSetupMapServiceSwitch();

  return (
    <fieldset className={'position-absolute p-2 map-service-switch__wrapper'}>
      <p className={'m-0'}>Выбор сервиса карты:</p>
      <div>
        <input
          className={'cursor-pointer'}
          type="radio"
          id="dtw"
          value="dtw"
          checked={value === 'dtw'}
          onChange={onChange}
        />
        <label className={'mx-1'} htmlFor="dtw">
          Карта DTW
        </label>
      </div>
      <div>
        <input
          className={'cursor-pointer'}
          type="radio"
          id="egip"
          value="egip"
          checked={value === 'egip'}
          onChange={onChange}
        />
        <label className={'mx-1'} htmlFor="egip">
          Карта ЕГИП
        </label>
      </div>
    </fieldset>
  );
};
