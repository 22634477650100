import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { TabsOdh } from './Tabs.Odh';

vi.mock('app/selectors/useSelectors/useEditorCard.Selector');

describe('🐛 spec Tabs.Odh', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useEditorCardSelector.mockReturnValue({});

    // 🔥 Act
    const wrapper = shallow(
      <TabsOdh propertyTab={''} onPropertyTab={() => {}} />,
    );

    // ❓ Assert
    expect(wrapper.text()).toBe('<Tabs /><Tabs />');
  });

  it('🧪 correct number of tabs', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useEditorCardSelector.mockReturnValue({
      is_avd: false,
      is_orphan_object: true,
    });

    // 🔥 Act
    const wrapper = shallow(
      <TabsOdh propertyTab={''} onPropertyTab={() => {}} />,
    );

    // ❓ Assert
    expect(wrapper.find('Tabs').first().prop('tabs')).toHaveLength(4);
  });

  it('🧪 call onPropertyTab when a tab is clicked', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useEditorCardSelector.mockReturnValue({ is_avd: true });
    const onPropertyTabSpy = vi.fn();

    // 🔥 Act
    const wrapper = shallow(
      <TabsOdh
        propertyTab={'characteristics'}
        onPropertyTab={onPropertyTabSpy}
      />,
    );

    wrapper.find('Tabs').first().simulate('change', {}, 'options');

    // ❓ Assert
    expect(onPropertyTabSpy).toHaveBeenCalledWith({}, 'options');
  });

  it('🧪 tabs', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useEditorCardSelector.mockReturnValue({});
    const onPropertyTabSpy = vi.fn();

    // 🔥 Act
    const wrapper = shallow(
      <TabsOdh propertyTab={'propertyTab'} onPropertyTab={onPropertyTabSpy} />,
    );

    // ❓ Assert
    expect(wrapper.childAt(0).props()).toStrictEqual({
      onChange: onPropertyTabSpy,
      tabs: [
        {
          isOrphanObject: true,
          label: 'Исходные данные',
          value: 'characteristics',
        },
        { isOrphanObject: true, label: 'Основные параметры', value: 'options' },
        { isOrphanObject: true, label: 'Уборочные данные', value: 'cleaning' },
        {
          label: 'Характеристики движения (архивные данные)',
          value: 'movement-characteristics',
        },
        { label: 'Ремонты', value: 'repairs' },
      ],
      value: 'propertyTab',
    });
    expect(wrapper.childAt(1).props()).toStrictEqual({
      onChange: onPropertyTabSpy,
      tabs: [
        { label: 'Проекты', value: 'repairs_plan' },
        { isOrphanObject: true, label: 'Документы', value: 'docs' },
        { label: 'Ордера на работы', value: 'orders' },
      ],
      value: 'propertyTab',
    });
  });
});
