import './Layout.scss';

import MapCard from 'app/pages/cardsOgh/components/Map/MapCard';
import { PreloadedCard } from 'app/pages/cardsOgh/components/PreloadedCard/PreloadedCard';
import { ActionTree } from 'app/pages/cardsOgh/components/RightPanelCard/components/ActionTree/ActionTree';
import cn from 'classnames';
import React from 'react';

/**
 * Компонент Layout.
 *
 * @param {object} root0 - Параметры.
 * @param {*} root0.children - Дочерние элементы.
 * @param {boolean} root0.map - Флаг отображения карты.
 * @returns {React.ReactElement}
 */
export const Layout = ({ children, map }) => {
  const className = cn('h-100 position-relative card-ogh-global-container', {
    'card-ogh-global-container__white': !!map,
  });

  return (
    <section className={className}>
      <ActionTree>
        <PreloadedCard>
          {/* <Dialogs> */}
          <MapCard active={map}>{children}</MapCard>
          {/* </Dialogs> */}
        </PreloadedCard>
      </ActionTree>
    </section>
  );
};
