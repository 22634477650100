import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';
import { IGeometryNeighbours } from 'types/api';

import { getColumnsNeighboursTable } from './getColumns.Neighbours.Table';

interface NeighboursTableProps {
  neighbors: IGeometryNeighbours[];
  count: number;
  isLoading: boolean;
}

/**
 * Таблица Смежных объектов.
 *
 * @param props - Свойства.
 * @param props.neighbors - Соседи.
 * @param props.count - Количество соседей.
 * @param props.isLoading - Загрузка.
 * @returns - Таблица.
 */
export const TableNeighbours = ({
  neighbors,
  count,
  isLoading,
}: NeighboursTableProps) => {
  return (
    <div className={'d-flex h-100 '}>
      <Table
        columns={getColumnsNeighboursTable()}
        data={neighbors}
        total={count}
        isLoading={isLoading}
      />
    </div>
  );
};
