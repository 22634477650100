import { MountingModeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/MountingModeId.RF';

import { useSetupMountingModeIdField } from './useSetup.MountingModeId.Field';

/**
 * MountingModeIdField.
 *
 * @param {object} props - Props.
 * @returns {JSX.Element}
 */
export const MountingModeIdField = (props) => {
  const { disabled, filter } = useSetupMountingModeIdField();
  return (
    <MountingModeIdRF
      {...props}
      filter={filter}
      required={true}
      disabled={disabled}
    />
  );
};
