import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import React from 'react';
import { Field } from 'react-final-form';

import { FileListKnowledgeBase } from '../FileList.KnowledgeBase';

/**
 * Список файлов ссылкой FF с видео проигрывателем.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Флаг редактирования.
 * @returns {React.ReactElement}
 */
export const FileListKnowledgeBaseField = ({ disabled }) => {
  return (
    <Field
      disabled={disabled}
      name={'file_list'}
      component={withFinalForm(FileListKnowledgeBase)}
    />
  );
};
