import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { vi } from 'vitest';

import { useSetupMountingModeIdField } from './useSetup.MountingModeId.Field';

vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');

describe('🐛 MountingModeIdField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);

    // 🔥 Act
    const res = useSetupMountingModeIdField();

    // ❓ Assert
    expect(res).toStrictEqual({
      disabled: false,
      filter: expect.any(Function),
    });
  });

  it('🧪 filter', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    const dict = [{ fz_spr: 'spr21' }, { fz_spr: 'spr20' }];

    // 🔥 Act
    const { filter } = useSetupMountingModeIdField();
    const filteredDict = filter(dict);

    // ❓ Assert
    expect(filteredDict).toEqual([{ fz_spr: 'spr21' }]);
  });

  it.each`
    editMode     | useIsEditCurrentObject | expected
    ${true}      | ${true}                | ${false}
    ${true}      | ${false}               | ${true}
    ${true}      | ${undefined}           | ${true}
    ${false}     | ${true}                | ${true}
    ${false}     | ${false}               | ${true}
    ${false}     | ${undefined}           | ${true}
    ${undefined} | ${true}                | ${true}
    ${undefined} | ${false}               | ${true}
    ${undefined} | ${undefined}           | ${true}
  `(
    '🧪 disabled: editMode=$editMode | useIsEditCurrentObject=$useIsEditCurrentObject | expected=$expected',
    ({ editMode, useIsEditCurrentObject, expected }) => {
      expect.hasAssertions();

      // ☣️ Arrange (всякие моки)
      useMode.mockReturnValue({ editMode });
      useIsEditCurrentObjectIsExternalSystem.mockReturnValue(
        useIsEditCurrentObject,
      );

      // 🔥 Act
      const res = useSetupMountingModeIdField();

      // ❓ Assert
      expect(res.disabled).toBe(expected);
    },
  );
});
