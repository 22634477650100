import React from 'react';

import { ToggleButton } from '../ToggleButton';
import { useSetupReonButtonWithAlert } from './useSetup.ReonButtonWithAlert';

const content = {
  off: {
    className: 'geom-button check-geometry-reon-off material-icons',
    title: 'Показать пересечения с данными ИС РЕОН',
  },
  on: {
    className: 'geom-button check-geometry-reon-on material-icons',
    title: 'Скрыть пересечения с данными ИС РЕОН',
  },
};

/**
 * Кнопка Показать пересечения с данными ИС РЕОН с алертом.
 *
 * @param {object} props - Пропсы.
 * @param {(message: string)=> void} props.showAlert - Функция вызова Алерта.
 * @returns {React.ReactElement}
 */
export const ReonButtonWithAlert = ({ showAlert }) => {
  const {
    hasReonIntersections,
    isLoading,
    checkGeometryReonByButton,
    isMapLoaded,
  } = useSetupReonButtonWithAlert({ showAlert });

  return (
    <ToggleButton
      disabled={!isMapLoaded}
      active={hasReonIntersections}
      content={content}
      onClick={checkGeometryReonByButton}
      isLoading={isLoading}
    />
  );
};
