import { fetchNeighboursGeometry } from 'app/api/requests/geometry/fetchNeighboursGeometry';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Мутация получения смежных ОГХ.
 *
 * @param root0 - Параметры мутации.
 * @param root0.onSuccess - Обработчик успешного завершения запроса.
 * @returns Метод и данные.
 */
export const useGetNeighboursMuttation = ({
  onSuccess,
}: {
  onSuccess: (
    data: Awaited<ReturnType<typeof fetchNeighboursGeometry>>,
  ) => void;
}) => {
  const [getNeighbours, { isLoading, data }] = useMutationAdaptor(
    fetchNeighboursGeometry,
    {
      onSuccess,
    },
  );

  return {
    count: data?.count,
    getNeighbours,
    isLoading,
    neighbors: data?.data,
    radius: data?.radius,
  };
};
